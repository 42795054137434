import React, { useState } from 'react';
import '../../styles/HomePage/Faq.css';
// import FaqTiles from './FaqTiles';
import { ChevronUp, ChevronDown } from 'lucide-react';
import { useNavigate } from "react-router-dom";
import { FAQ_BUTTON_TEXT, FAQ_DESCRIPTION, FAQ_HEADING_1, FAQ_HEADING_2, FAQ_TILE_1_DESCRIPTION, FAQ_TILE_1_HEADING, FAQ_TILE_2_DESCRIPTION, FAQ_TILE_2_HEADING, FAQ_TILE_3_DESCRIPTION, FAQ_TILE_3_HEADING, FAQ_TILE_4_DESCRIPTION, FAQ_TILE_4_HEADING, FAQ_TILE_5_DESCRIPTION, FAQ_TILE_5_HEADING } from '../../constants';

function Faq() {
    return (
        <div>
            <FaqMainContainerDesktop></FaqMainContainerDesktop>
            <FaqMainContainerTablet></FaqMainContainerTablet>
        </div>
    );
}

function FaqMainContainerDesktop() {
    const navigate = useNavigate();
    return (
        <div className='faq-main-container-desktop'>
            <div className='faq-row'>
                <div className='faq-left-side'>
                    <div className='faq-left-faq'>
                        {FAQ_HEADING_1}
                    </div>
                    <div className='faq-left-heading'>
                        {FAQ_HEADING_2}
                    </div>
                    <div className='faq-left-desc'>
                        {FAQ_DESCRIPTION}
                    </div>
                    <button className='view-all-faq-button' onClick={() => {
                        // window.scrollTo(0, 0);
                        navigate("/faq/");
                    }}>
                        {FAQ_BUTTON_TEXT}
                    </button>
                </div>
                <FaqTiles></FaqTiles>
            </div>
        </div>
    );
}

function FaqMainContainerTablet() {
    const navigate = useNavigate();
    return (
        <div className='faq-main-container-tablet'>
            <div className='faq-row'>
                <div className='faq-left-side'>
                    <div className='faq-left-faq'>
                        {FAQ_HEADING_1}
                    </div>
                    <div className='faq-left-heading'>
                        {FAQ_HEADING_2}
                    </div>
                    <div className='faq-left-desc'>
                        {FAQ_DESCRIPTION}
                    </div>

                </div>
                <div className='faq-right-side'>
                    <FaqTilesTablet></FaqTilesTablet>
                    <button className='view-all-faq-button' onClick={() => {
                        // window.scrollTo(0, 0);
                        navigate("/faq/");
                    }}>
                        {FAQ_BUTTON_TEXT}
                    </button>
                </div>
            </div>
        </div>
    );
}

function FaqTiles() {
    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [visible3, setVisible3] = useState(false);
    const [visible4, setVisible4] = useState(false);
    const [visible5, setVisible5] = useState(false);

    return (
        <div className='expandable-tiles-column'>
            <ExpandableTile visible={visible1} onClick={(event) => {
                event.preventDefault();
                setVisible1(!visible1);
                if (visible1 === false) {
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                }
            }} title={FAQ_TILE_1_HEADING} content={FAQ_TILE_1_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible2(!visible2);
                if (visible2 === false) {
                    setVisible1(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                }
            }} visible={visible2} title={FAQ_TILE_2_HEADING} content={FAQ_TILE_2_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible3(!visible3);
                if (visible3 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible4(false);
                    setVisible5(false);
                }
            }} visible={visible3} title={FAQ_TILE_3_HEADING} content={FAQ_TILE_3_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible4(!visible4);
                if (visible4 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible5(false);
                }
            }} visible={visible4} title={FAQ_TILE_4_HEADING} content={FAQ_TILE_4_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible5(!visible5);
                if (visible5 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                }
            }} visible={visible5} title={FAQ_TILE_5_HEADING} content={FAQ_TILE_5_DESCRIPTION}></ExpandableTile>
        </div>
    );
}

function FaqTilesTablet() {
    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [visible3, setVisible3] = useState(false);
    const [visible4, setVisible4] = useState(false);
    const [visible5, setVisible5] = useState(false);

    return (
        <div className='expandable-tiles-column-tablet'>
            <ExpandableTileTablet visible={visible1} onClick={(event) => {
                event.preventDefault();
                setVisible1(!visible1);
                if (visible1 === false) {
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                }
            }} title={FAQ_TILE_1_HEADING} content={FAQ_TILE_1_DESCRIPTION}></ExpandableTileTablet>
            <ExpandableTileTablet onClick={(event) => {
                event.preventDefault();
                setVisible2(!visible2);
                if (visible2 === false) {
                    setVisible1(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                }
            }} visible={visible2} title={FAQ_TILE_2_HEADING} content={FAQ_TILE_2_DESCRIPTION}></ExpandableTileTablet>
            <ExpandableTileTablet onClick={(event) => {
                event.preventDefault();
                setVisible3(!visible3);
                if (visible3 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible4(false);
                    setVisible5(false);
                }
            }} visible={visible3} title={FAQ_TILE_3_HEADING} content={FAQ_TILE_3_DESCRIPTION}></ExpandableTileTablet>
            <ExpandableTileTablet onClick={(event) => {
                event.preventDefault();
                setVisible4(!visible4);
                if (visible4 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible5(false);
                }
            }} visible={visible4} title={FAQ_TILE_4_HEADING} content={FAQ_TILE_4_DESCRIPTION}></ExpandableTileTablet>
            <ExpandableTileTablet onClick={(event) => {
                event.preventDefault();
                setVisible5(!visible5);
                if (visible5 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                }
            }} visible={visible5} title={FAQ_TILE_5_HEADING} content={FAQ_TILE_5_DESCRIPTION}></ExpandableTileTablet>
        </div>
    );
}

function ExpandableTile({ visible, title, content, onClick }) {
    return (
        <div className='expandable-tile'>
            <div className='expandable-tile-heading' onClick={onClick}>
                <div className='expandable-tile-heading-text'>
                    {title}
                </div>
                <div onClick={onClick}>
                    {visible ? <ChevronUp /> : <ChevronDown />}
                </div>
            </div>
            <div style={{
                display: `${visible ? 'block' : 'none'}`,
                overflow: 'hidden',
                margin: '20px 0px 0px 0px',
                lineHeight: '24px'
            }}>
                {visible && content}
            </div>
        </div>
    );
}

function ExpandableTileTablet({ visible, title, content, onClick }) {
    return (
        <div className='expandable-tile'>
            <div className='expandable-tile-heading'>
                <div className='expandable-tile-heading-text' onClick={onClick}>
                    {title}
                </div>
                <div onClick={onClick}>
                    {visible ? <ChevronUp /> : <ChevronDown />}
                </div>
            </div>
            <div style={{
                display: `${visible ? 'block' : 'none'}`,
                overflow: 'hidden',
                margin: '20px 0px 0px 0px',
                width: '93%',
                lineHeight: '24px'
            }}>
                {visible && content}
            </div>
        </div>
    );
}

export default Faq;