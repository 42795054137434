import React, { useState, useEffect } from 'react';

const texts = [
  "\"Live Bold, Not Bald\"",
  "Ask us for our flexible payment plans.",
  "Internationally trained and certified SMP Artist."
];

const FadeAndSlideText = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeState, setFadeState] = useState('fade-in');

  useEffect(() => {
    const fadeDuration = 1000; // Duration of fade-in and fade-out in ms
    const intervalDuration = 5000; // Time to display each text in ms

    const interval = setInterval(() => {
      setFadeState('fade-out'); // Start fade out

      setTimeout(() => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % texts.length);
        setFadeState('fade-in'); // Start fade in
      }, fadeDuration);

    }, intervalDuration);

    return () => clearInterval(interval);
  }, []);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: '#023273',
      height: '45px',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      overflow: 'hidden',
      position: 'relative',
    },
    text: {
      opacity: 0,
      visibility: 'hidden',
      transform: 'translateY(20px)', // Start position
      transition: 'opacity 1s ease-in-out, visibility 1s ease-in-out, transform 1s ease-in-out',
      position: 'absolute',
      width: '100%',
      textAlign: 'center',
    },
    fadeIn: {
      opacity: 1,
      visibility: 'visible',
      transform: 'translateY(0)', // End position
    },
    fadeOut: {
      opacity: 0,
      visibility: 'hidden',
      transform: 'translateY(20px)', // End position
    },
  };

  return (
    <div style={styles.container}>
      <p
        style={{
          ...styles.text,
          ...(fadeState === 'fade-in' ? styles.fadeIn : styles.fadeOut),
        }}
      >
        {texts[currentIndex]}
      </p>
    </div>
  );
};

export default FadeAndSlideText;
