import React from 'react';
import '../styles/HomePage/TestPage.css';
import { useSelector, useDispatch } from 'react-redux';
import { selectCount, setCount } from '../state/slices';
import { selectName, setName } from '../state/slices';

function TestPage() {
    const count = useSelector(selectCount);
    const name = useSelector(selectName);
    const dispatch = useDispatch();

    function increment() {
        dispatch(setCount(count + 1));
    }

    function decrement() {
        dispatch(setCount(count - 1));
    }

    function toggleName() {
        dispatch(setName(name === 'Tom' ? 'Nick' : 'Tom'));
    }

    return (
        <div className='b-parent'>
            <div className='b-at-cl'>
                <div className='b-at-rw'>
                    <button className='at-button-1' onClick={decrement}>-</button>
                    <span className='the-display'>{count}</span>
                    <button className='at-button-1' onClick={increment}>+</button>
                </div>
                <span className='the-display'>{name}</span>
                <button className='at-button-2' onClick={toggleName}>Toggle name</button>
            </div>
        </div>
    );
}

export default TestPage;
