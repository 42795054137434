import { createSlice } from '@reduxjs/toolkit'

export const countSlice = createSlice({
  name: 'count',
  initialState: { value: 0 },
  reducers: {
    setCount: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectCount = (state) => state.count.value
export const { setCount } = countSlice.actions
export const countReducer = countSlice.reducer

// ................................................................................

export const nameSlice = createSlice({
  name: 'name',
  initialState: { value: 'Tom' },
  reducers: {
    setName: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectName = (state) => state.name.value
export const { setName } = nameSlice.actions
export const nameReducer = nameSlice.reducer

// ................................................................................

export const genderSlice = createSlice({
  name: 'gender',
  initialState: { value: '--select--' },
  reducers: {
    setGender: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectGender = (state) => state.gender.value
export const { setGender } = genderSlice.actions
export const genderReducer = genderSlice.reducer

// ................................................................................

export const serviceSlice = createSlice({
  name: 'service',
  initialState: { value: 'SMP' },
  reducers: {
    setService: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectService = (state) => state.service.value
export const { setService } = serviceSlice.actions
export const serviceReducer = serviceSlice.reducer

// ................................................................................

export const showDialogSlice = createSlice({
  name: 'showDialog',
  initialState: { value: false },
  reducers: {
    setShowDialog: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectShowDialog = (state) => state.showDialog.value
export const { setShowDialog } = showDialogSlice.actions
export const showDialogReducer = showDialogSlice.reducer

// ................................................................................

// Function to get persisted value from localStorage, default to true if not found
const getInitialState = () => {
  const savedState = localStorage.getItem('showImgDialog1');
  return savedState !== null ? JSON.parse(savedState) : true;
};

export const showImgDialog1Slice = createSlice({
  name: 'showImgDialog1',
  initialState: { value: getInitialState() },  // Load initial state from localStorage
  reducers: {
    setShowImgDialog1: (state, action) => {
      state.value = action.payload;
      localStorage.setItem('showImgDialog1', JSON.stringify(action.payload)); // Persist state change
    },
  },
});

export const selectShowImgDialog1 = (state) => state.showImgDialog1.value;
export const { setShowImgDialog1 } = showImgDialog1Slice.actions;
export const showImgDialog1Reducer = showImgDialog1Slice.reducer;

// ................................................................................

export const showLoadingSlice = createSlice({
  name: 'showLoading',
  initialState: { value: false },
  reducers: {
    setShowLoading: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectShowLoading = (state) => state.showLoading.value
export const { setShowLoading } = showLoadingSlice.actions
export const showLoadingReducer = showLoadingSlice.reducer

// ................................................................................

export const firstNameSlice = createSlice({
  name: 'firstName',
  initialState: { value: '' },
  reducers: {
    setFirstName: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectFirstName = (state) => state.firstName.value
export const { setFirstName } = firstNameSlice.actions
export const firstNameReducer = firstNameSlice.reducer

// ................................................................................

export const lastNameSlice = createSlice({
  name: 'lastName',
  initialState: { value: '' },
  reducers: {
    setLastName: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectLastName = (state) => state.lastName.value
export const { setLastName } = lastNameSlice.actions
export const lastNameReducer = lastNameSlice.reducer

// ................................................................................

export const phoneSlice = createSlice({
  name: 'phone',
  initialState: { value: '' },
  reducers: {
    setPhone: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectPhone = (state) => state.phone.value
export const { setPhone } = phoneSlice.actions
export const phoneReducer = phoneSlice.reducer

// ................................................................................

export const emailSlice = createSlice({
  name: 'email',
  initialState: { value: '' },
  reducers: {
    setEmail: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectEmail = (state) => state.email.value
export const { setEmail } = emailSlice.actions
export const emailReducer = emailSlice.reducer

// ................................................................................

export const messageSlice = createSlice({
  name: 'message',
  initialState: { value: '' },
  reducers: {
    setMessage: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectMessage = (state) => state.message.value
export const { setMessage } = messageSlice.actions
export const messageReducer = messageSlice.reducer

// ................................................................................

export const citySlice = createSlice({
  name: 'city',
  initialState: { value: '' },
  reducers: {
    setCity: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectCity = (state) => state.city.value
export const { setCity } = citySlice.actions
export const cityReducer = citySlice.reducer

// ................................................................................

export const dialogTitleSlice = createSlice({
  name: 'dialogTitle',
  initialState: { value: '' },
  reducers: {
    setDialogTitle: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectDialogTitle = (state) => state.dialogTitle.value
export const { setDialogTitle } = dialogTitleSlice.actions
export const dialogTitleReducer = dialogTitleSlice.reducer

// ................................................................................

export const dialogDescSlice = createSlice({
  name: 'dialogDesc',
  initialState: { value: '' },
  reducers: {
    setDialogDesc: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectDialogDesc = (state) => state.dialogDesc.value
export const { setDialogDesc } = dialogDescSlice.actions
export const dialogDescReducer = dialogDescSlice.reducer

// ................................................................................