import React, { useState, useEffect } from 'react';
import '../styles/FaqPage/FaqPage.css';
import Footer from '../components/HomePage/Footer';
import Navbar from '../components/HomePage/Navbar';
import { ChevronUp, ChevronDown } from 'lucide-react';
import { FAQ_TILE_10_DESCRIPTION, FAQ_TILE_10_HEADING, FAQ_TILE_1_DESCRIPTION, FAQ_TILE_1_HEADING, FAQ_TILE_2_DESCRIPTION, FAQ_TILE_2_HEADING, FAQ_TILE_3_DESCRIPTION, FAQ_TILE_3_HEADING, FAQ_TILE_4_DESCRIPTION, FAQ_TILE_4_HEADING, FAQ_TILE_5_DESCRIPTION, FAQ_TILE_5_HEADING, FAQ_TILE_6_DESCRIPTION, FAQ_TILE_6_HEADING, FAQ_TILE_7_DESCRIPTION, FAQ_TILE_7_HEADING, FAQ_TILE_8_DESCRIPTION, FAQ_TILE_8_HEADING, FAQ_TILE_9_DESCRIPTION, FAQ_TILE_9_HEADING } from '../constants';
import FloatingWhatsAppButton from '../components/FloatingWhatsAppButton';

function FaqPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    return (
        <div style={{ backgroundColor: "#fafafa", scrollbarWidth: 'none' }}>
            <div className='space-between-navbar'></div>
            <Faq></Faq>
            <Footer></Footer>
            <FloatingWhatsAppButton></FloatingWhatsAppButton>
            <Navbar></Navbar>
        </div>
    );
}


function Faq() {
    return (
        <div>
            <FaqMainContainer></FaqMainContainer>
        </div>
    );
}


function FaqMainContainer() {
    return (
        <div className='fp-faq-main-container'>
            <div className='fp-faq-row'>
                <FaqMainHeading></FaqMainHeading>
                <DottedLine></DottedLine>
                <div className='fp-faq-right-side'>
                    <FaqTiles></FaqTiles>
                </div>
            </div>
        </div>
    );
}

function FaqMainHeading() {
    return (
        <div className='fp-faq-main-heading'>
            <div className='fp-faq-big-text'>
                FAQ
            </div>
            <div className='fp-faq-small-text'>
                If the answer to your question is not listed below, please email us at info@microincsmp.com and we will get back to you as soon as we can!
            </div>
        </div>
    );
}

function DottedLine() {
    return (
        <div className='fp-dotted-line-row'>
            <div className='fp-dotted-line'></div>
        </div>
    );
}

function FaqTiles() {
    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [visible3, setVisible3] = useState(false);
    const [visible4, setVisible4] = useState(false);
    const [visible5, setVisible5] = useState(false);
    // ................................................
    const [visible6, setVisible6] = useState(false);
    const [visible7, setVisible7] = useState(false);
    const [visible8, setVisible8] = useState(false);
    const [visible9, setVisible9] = useState(false);
    const [visible10, setVisible10] = useState(false);

    return (
        <div className='fp-expandable-tiles-column'>
            <ExpandableTile visible={visible1} onClick={(event) => {
                event.preventDefault();
                setVisible1(!visible1);
                if (visible1 === false) {
                    // setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
            }} title={FAQ_TILE_1_HEADING} content={FAQ_TILE_1_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible2(!visible2);
                if (visible2 === false) {
                    setVisible1(false);
                    // setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
            }} visible={visible2} title={FAQ_TILE_2_HEADING} content={FAQ_TILE_2_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible3(!visible3);
                if (visible3 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    // setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
                // <img className='home-video-component' src={SMP_FOR_SCARS_BANNER_IMAGE}></img>
            }} visible={visible3} title={FAQ_TILE_3_HEADING} content={FAQ_TILE_3_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible4(!visible4);
                if (visible4 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    // setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
            }} visible={visible4} title={FAQ_TILE_4_HEADING} content={FAQ_TILE_4_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible5(!visible5);
                if (visible5 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    // setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
            }} visible={visible5} title={FAQ_TILE_5_HEADING} content={FAQ_TILE_5_DESCRIPTION}></ExpandableTile>
            {/* ----------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
            <ExpandableTile visible={visible6} onClick={(event) => {
                event.preventDefault();
                setVisible6(!visible6);
                if (visible6 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    // setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
            }} title={FAQ_TILE_6_HEADING} content={FAQ_TILE_6_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible7(!visible7);
                if (visible7 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    // setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
            }} visible={visible7} title={FAQ_TILE_7_HEADING} content={FAQ_TILE_7_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible8(!visible8);
                if (visible8 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    // setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
                // <img className='home-video-component' src={SMP_FOR_SCARS_BANNER_IMAGE}></img>
            }} visible={visible8} title={FAQ_TILE_8_HEADING} content={FAQ_TILE_8_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible9(!visible9);
                if (visible9 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    // setVisible9(false);
                    setVisible10(false);
                }
            }} visible={visible9} title={FAQ_TILE_9_HEADING} content={FAQ_TILE_9_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible10(!visible10);
                if (visible10 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    // setVisible10(false);
                }
            }} visible={visible10} title={FAQ_TILE_10_HEADING} content={FAQ_TILE_10_DESCRIPTION}></ExpandableTile>

        </div>
    );
}

function ExpandableTile({ visible, title, content, onClick }) {
    return (
        <div className='fp-expandable-tile'>
            <div className='fp-expandable-tile-heading'>
                <div className='fp-expandable-tile-heading-text' onClick={onClick}>
                    {title}
                </div>
                <div onClick={onClick} >
                    {visible ? <ChevronUp /> : <ChevronDown />}
                </div>
            </div>
            <div style={{
                display: `${visible ? 'block' : 'none'}`,
                overflow: 'hidden',
                margin: '20px 0px 0px 0px',
                width: '93%',
                lineHeight: '24px'
            }}>
                {visible && content}
            </div>
        </div>
    );
}

export default FaqPage;