export function validEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
}

export function validPhoneNumber(phoneNumber) {
    const regex = /^\d{10}$/;
    return regex.test(phoneNumber);
}

export function isValidString(str) {
    return str !== undefined && str !== null && str !== 'undefined' && str !== 'null' && str.trim() !== '';
}

export const getUrlParams = () => {
    try {
        const params = new URLSearchParams(window.location.search);
        return {
            utmSource: params.get('utm_source') || 'defaultSource',
            utmMedium: params.get('utm_medium') || 'defaultMedium',
            utmCampaign: params.get('utm_campaign') || 'defaultCampaign',
            utmAdGroupName: params.get('utm_adgroupname') || 'defaultAdGroupName',
            campaignId: params.get('campaignid') || 'defaultCampaignId',
            adGroupId: params.get('adgroupid') || 'defaultAdGroupId',
            utmTerm: params.get('utm_term') || 'defaultTerm',
            utmDevice: params.get('utm_device') || 'defaultDevice',
            utmAdName: params.get('utm_adname') || 'defaultAdName',
            utmMatchType: params.get('utm_matchtype') || 'defaultMatchType',
            utmNetwork: params.get('utm_network') || 'defaultNetwork',
        };
    } catch (error) {
        console.error('Error parsing URL parameters:', error);
        return {
            utmSource: 'defaultSource',
            utmMedium: 'defaultMedium',
            utmCampaign: 'defaultCampaign',
            utmAdGroupName: 'defaultAdGroupName',
            campaignId: 'defaultCampaignId',
            adGroupId: 'defaultAdGroupId',
            utmTerm: 'defaultTerm',
            utmDevice: 'defaultDevice',
            utmAdName: 'defaultAdName',
            utmMatchType: 'defaultMatchType',
            utmNetwork: 'defaultNetwork',
        };
    }
};
