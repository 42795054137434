import React, { useState } from 'react';
import '../../styles/HomePage/About.css';
import { ChevronUp, ChevronDown } from 'lucide-react';
import { FOUNDER_IMAGE, ABOUT_TEXT, ABOUT_TITLE, ABOUT_BUTTON_TEXT, CERTIFICATE_1, CERTIFICATE_2 } from '../../constants';
import useWindowDimensions from '../../hooks/useWindowDimensions';


function About() {
    return (
        <div>
            <AboutMainContainerDesktop></AboutMainContainerDesktop>
            <AboutMainContainerTablet></AboutMainContainerTablet>
            <AboutMainContainerMobile></AboutMainContainerMobile>
        </div>
    );
}

function AboutMainContainerDesktop() {
    const { width } = useWindowDimensions();
    const isDesktop = width >= 1025;
    const isTablet = width >= 768 && width <= 1024;
    const isMobile = width <= 767;

    function reValue({ desktop, tablet, mobile }) {
        if (isDesktop) {
            return desktop;
        }
        if (isTablet) {
            return tablet;
        }
        if (isMobile) {
            return mobile;
        }
    }
    if (isMobile || isTablet) {
        return (<div></div>);
    }
    return (
        <div style={{
            // backgroundColor: 'green',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <div className='about-main-container-desktop'>
                <div className='about-row'>
                    <div className='about-left-side'>
                        <div className='about-left-heading'>
                            {ABOUT_TITLE}
                        </div>
                        <div className='about-left-desc'>
                            <span style={{
                                fontWeight: 'bold',
                                // color: 'grey',
                                fontSize: '18px',
                            }}>Chetan Deepak Mahajan</span><br />
                            Founder and Artist<br /><br />

                            Hello, I'm Chetan, an internationally trained and certified SMP artist through Leaders Academy.<br /><br />

                            My journey into SMP was born from my own experience with hair loss, leading me to discover this powerful solution that restores not just hairlines but also self-confidence. Under the mentorship of Jay Grewal, founder of Elixir Pigmentation London, I've honed my skills to provide a compassionate and expert approach to every client's unique journey.<br /><br />

                            My aim is to transform lives by combining the precision of technology with the art of SMP and help individuals reclaim their confidence, one scalp at a time.<br /><br />

                            Start your SMP journey and restore your confidence today!
                        </div>
                        <button className='view-all-about-button' onClick={() => {
                            // window.scrollTo(0, 3800);
                            window.scrollTo({
                                top: 1650,
                                behavior: 'smooth'
                            });
                        }}>
                            {ABOUT_BUTTON_TEXT}
                        </button>
                    </div>
                    <div style={{
                        backgroundColor: 'black',
                        height: 'fit-content',
                        display: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '0px 70px 0px 70px',
                        margin: '0px 0px 0px 80px',
                    }}>
                        <div className='founder-image-container'>
                            <img src={FOUNDER_IMAGE}></img>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{
                // backgroundColor: 'red',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0px 0px 150px 0px'
            }}>
                <div style={{
                    // backgroundColor: 'green',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '89.5%'
                }}>
                    <img style={{
                        // backgroundColor: 'pink',
                        width: '500px',
                        height: 'auto',
                        margin: '0px 0px 0px 0px'
                    }} src={CERTIFICATE_1} />
                    <img style={{
                        // backgroundColor: 'pink',
                        width: '500px',
                        height: 'auto',
                        margin: '0px 0px 0px 50px'
                    }} src={CERTIFICATE_2} />
                </div>
            </div>

        </div>
    );
}

function AboutMainContainerTablet() {
    const { width } = useWindowDimensions();
    const isDesktop = width >= 1025;
    const isTablet = width >= 768 && width <= 1024;
    const isMobile = width <= 767;

    function reValue({ desktop, tablet, mobile }) {
        if (isDesktop) {
            return desktop;
        }
        if (isTablet) {
            return tablet;
        }
        if (isMobile) {
            return mobile;
        }
    }
    if (isMobile || isDesktop) {
        return (<div></div>);
    }
    return (
        <div style={{
            // backgroundColor: 'green',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <div className='about-main-container-tablet'>
                <div className='about-row'>
                    <div className='about-left-side'>
                        {/* <div className='about-left-about'>
                        FAQ
                    </div> */}
                        <div className='about-left-heading'>
                            {ABOUT_TITLE}
                        </div>
                        <div className='about-left-desc'>
                            <span style={{
                                fontWeight: 'bold',
                                // color: 'grey',
                                fontSize: '18px',
                            }}>Chetan Deepak Mahajan</span><br />
                            Founder and Artist<br /><br />

                            Hello, I'm Chetan, an internationally trained and certified SMP artist through Leaders Academy.<br /><br />

                            My journey into SMP was born from my own experience with hair loss, leading me to discover this powerful solution that restores not just hairlines but also self-confidence. Under the mentorship of Jay Grewal, founder of Elixir Pigmentation London, I've honed my skills to provide a compassionate and expert approach to every client's unique journey.<br /><br />

                            My aim is to transform lives by combining the precision of technology with the art of SMP and help individuals reclaim their confidence, one scalp at a time.<br /><br />

                            Start your SMP journey and restore your confidence today!
                        </div>
                        <button className='view-all-about-button' onClick={() => {
                            // window.scrollTo(0, 4900);
                            window.scrollTo({
                                top: 1650,
                                behavior: 'smooth'
                            });
                        }}>
                            {ABOUT_BUTTON_TEXT}
                        </button>
                    </div>
                    <div>
                        <img className='founder-image-container' src={FOUNDER_IMAGE}></img>
                    </div>
                </div>
            </div>
            <div style={{
                // backgroundColor: 'red',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0px 0px 150px 0px'
            }}>
                <div style={{
                    // backgroundColor: 'green',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '80%'
                }}>
                    <img style={{
                        // backgroundColor: 'pink',
                        width: '38vw',
                        height: 'auto',
                        margin: '0px 0px 0px 0px'
                    }} src={CERTIFICATE_1} />
                    <img style={{
                        // backgroundColor: 'pink',
                        width: '38vw',
                        height: 'auto',
                        margin: '0px 0px 0px 30px'
                    }} src={CERTIFICATE_2} />
                </div>
            </div>
        </div>
    );
}

function AboutMainContainerMobile() {
    const { width } = useWindowDimensions();
    // const { width } = useWindowDimensions();
    const isDesktop = width >= 1025;
    const isTablet = width >= 768 && width <= 1024;
    const isMobile = width <= 767;

    function reValue({ desktop, tablet, mobile }) {
        if (isDesktop) {
            return desktop;
        }
        if (isTablet) {
            return tablet;
        }
        if (isMobile) {
            return mobile;
        }
    }
    return (
        <div className='about-main-container-mobile'>
            <div className='about-row'>
                <div className='about-left-side'>
                    <div className='about-left-heading'>
                        {ABOUT_TITLE}
                    </div>
                    <div className='about-left-desc'>
                        <span style={{
                            fontWeight: 'bold',
                            // color: 'grey',
                            fontSize: '18px',
                        }}>Chetan Deepak Mahajan</span><br />
                        Founder and Artist<br /><br />

                        Hello, I'm Chetan, an internationally trained and certified SMP artist through Leaders Academy.<br /><br />

                        My journey into SMP was born from my own experience with hair loss, leading me to discover this powerful solution that restores not just hairlines but also self-confidence. Under the mentorship of Jay Grewal, founder of Elixir Pigmentation London, I've honed my skills to provide a compassionate and expert approach to every client's unique journey.<br /><br />

                        My aim is to transform lives by combining the precision of technology with the art of SMP and help individuals reclaim their confidence, one scalp at a time.<br /><br />

                        Start your SMP journey and restore your confidence today!
                    </div>
                    <div>
                        <img className='founder-image-container' src={FOUNDER_IMAGE}></img>
                    </div>
                    <div>
                        <img className='founder-image-container' src={CERTIFICATE_1}></img>
                    </div>
                    <div>
                        <img className='founder-image-container' src={CERTIFICATE_2}></img>
                    </div>
                    <button className='view-all-about-button' onClick={() => {
                        // window.scrollTo(0, 6620);
                        window.scrollTo(reValue({
                            desktop: {
                                top: 700,
                                behavior: 'smooth'
                            },
                            tablet: {
                                top: 700,
                                behavior: 'smooth'
                            },
                            mobile: {
                                top: 1580,
                                behavior: 'smooth'
                            },
                        }));
                    }}>
                        {ABOUT_BUTTON_TEXT}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default About;