import React, { useState } from 'react';
import '../../styles/HomePage/Home.css';
import { HOME_VIDEO, SMP_FOR_WOMEN_BANNER_IMAGE } from '../../constants';

function Home() {
    return (
        <div>
            <HomeDesktop></HomeDesktop>
            <HomeMobile></HomeMobile>
        </div>
    );
}

function HomeDesktop() {
    return (
        <div className='home-video-container'>
            <video className='home-video-component' autoPlay muted loop>
                <source src={HOME_VIDEO} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            {/* <img className='home-video-component' src={SMP_FOR_WOMEN_BANNER_IMAGE}></img> */}
            <div className='home-video-overlay'>
                <div className='home-video-overlay-column'>
                    <span className='home-video-overlay-column-text1'>
                        SMP for Women
                    </span>
                    {/* <span className='home-video-overlay-column-text2'>
                        Scalp Micropigmentation
                    </span> */}
                    {/* <div className='home-video-overlay-column-text3'>
                        Fix your receding hairline, thinning hair, and balding crown with scalp micropigmentation.
                    </div> */}
                    {/* <button className="quoteButtonHome">
                        GET A FREE CONSULTATION
                    </button> */}
                </div>
            </div>
            {/* <Navbar></Navbar> */}
        </div>
    );
}

function HomeMobile() {
    return (
        <div className='home-video-container-mobile'>
            <video className='home-video-component' autoPlay muted loop>
                <source src={HOME_VIDEO} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            {/* <div className='home-video-component'>
                <iframe
                    className="vimeo-iframe"
                    src="https://player.vimeo.com/video/987940364?autoplay=1&muted=true&loop=1&controls=0"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                    title="Vimeo Video"
                ></iframe>
            </div> */}
            {/* <img className='home-video-component' src={SMP_FOR_WOMEN_BANNER_IMAGE}></img> */}
            <div className='home-video-overlay'>
                <div className='home-video-overlay-column'>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'center',
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            // backgroundColor: 'green',
                            width: '91%',
                        }}>
                            <span className='home-video-overlay-column-text1'>
                                SMP for Women
                            </span>
                            {/* <span className='home-video-overlay-column-text2'>
                                Scalp Micropigmentation
                            </span> */}
                            {/* <div className='home-video-overlay-column-text3'>
                                Fix your receding hairline, thinning hair, and balding crown with scalp micropigmentation.
                            </div> */}
                        </div>
                    </div>
                    <div style={{
                        // backgroundColor: 'red',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                    }}>
                        {/* <button className="quoteButtonHome">
                            GET A FREE CONSULTATION
                        </button> */}
                    </div>
                </div>
            </div>
            {/* <Navbar></Navbar> */}
        </div>
    );
}


// return (
//     <div className='home-video-container'>
//         <video className='home-video-component' autoPlay muted loop>
//             <source src={HOME_VIDEO} type="video/mp4" />
//             Your browser does not support the video tag.
//         </video>
//         <div className='home-video-overlay'>
//             <div>
//                 <div className='home-video-overlay-column'>
//                     <div className='home-video-overlay-column-text1'>
//                         {HOME_VIDEO_HEADER_1}
//                     </div>
//                     <div className='home-video-overlay-column-text2'>
//                         Scalp Micropigmentation
//                     </div>
//                     <div className='home-video-overlay-column-text3'>
//                         Scalp Micropigmentation is a highly effective, non-invasive, low maintenance solution to hair loss that delivers immediate, long term results.
//                     </div>
//                     <button className="quoteButtonHome">
//                         GET A FREE CONSULTATION
//                     </button>
//                 </div>
//             </div>
//         </div>
//         <Navbar></Navbar>
//     </div>
// ); 

{/* <div className='space-between-navbar'></div> */ }

export default Home;