import React from 'react';
import '../../styles/HomePage/CompareChart.css';
import { FaCircleCheck } from "react-icons/fa6";
import { LuBan } from "react-icons/lu";
import { BRAND_LOGO, CC_HEADING_1, CC_HEADING_2, SIDE_HEADING_1, SIDE_HEADING_2, SIDE_HEADING_3, SIDE_HEADING_4, SIDE_HEADING_5, UPPER_HEADING_1, UPPER_HEADING_1_IMAGE, UPPER_HEADING_2, UPPER_HEADING_2_IMAGE, UPPER_HEADING_3, UPPER_HEADING_3_IMAGE, UPPER_HEADING_4, UPPER_HEADING_4_IMAGE } from '../../constants';

function CompareChart() {
    return (
        <div className='compare-form-main-column'>
            <div className='compare-title-container'>
                <div className='compare-head-column'>
                    <div className='compare-text'>
                        {CC_HEADING_1}
                    </div>
                    <div className='compare-text2'>
                        {CC_HEADING_2}
                    </div>
                </div>
            </div>
            <CompareCard>
                <CRow>
                    <CellObject>
                        <HeaderLogoImage image={BRAND_LOGO} ></HeaderLogoImage>
                    </CellObject>
                    <CellObjectCenter>
                        <HeaderTitleColumn>
                            <HeaderTitleImage image={UPPER_HEADING_1_IMAGE}></HeaderTitleImage>
                            <HeaderTitleText>{UPPER_HEADING_1}</HeaderTitleText>
                        </HeaderTitleColumn>
                    </CellObjectCenter>
                    <CellObjectCenter>
                        <HeaderTitleColumn>
                            <HeaderTitleImage image={UPPER_HEADING_2_IMAGE}></HeaderTitleImage>
                            <HeaderTitleText>{UPPER_HEADING_2}</HeaderTitleText>
                        </HeaderTitleColumn>
                    </CellObjectCenter>
                    <CellObjectCenter>
                        <HeaderTitleColumn>
                            <HeaderTitleImage image={UPPER_HEADING_3_IMAGE}></HeaderTitleImage>
                            <HeaderTitleText>{UPPER_HEADING_3}</HeaderTitleText>
                        </HeaderTitleColumn>
                    </CellObjectCenter>
                    <CellObjectCenter>
                        <HeaderTitleColumn>
                            <HeaderTitleImage image={UPPER_HEADING_4_IMAGE}></HeaderTitleImage>
                            <HeaderTitleText>{UPPER_HEADING_4}</HeaderTitleText>
                        </HeaderTitleColumn>
                    </CellObjectCenter>
                </CRow>
                <CRowGrey>
                    <CellObject>
                        <LeftHeaderTitleText>
                            {SIDE_HEADING_1}
                        </LeftHeaderTitleText>
                    </CellObject>
                    <CellObjectCenter>
                        <Yes></Yes>
                    </CellObjectCenter>
                    <CellObjectCenter>
                        <No></No>
                    </CellObjectCenter>
                    <CellObjectCenter>
                        <Yes></Yes>
                    </CellObjectCenter>
                    <CellObjectCenter>
                        <Yes></Yes>
                    </CellObjectCenter>
                </CRowGrey>
                <CRow>
                    <CellObject>
                        <LeftHeaderTitleText>
                            {SIDE_HEADING_2}
                        </LeftHeaderTitleText>
                    </CellObject>
                    <CellObjectCenter>
                        <Yes></Yes>
                    </CellObjectCenter>
                    <CellObjectCenter>
                        <No></No>
                    </CellObjectCenter>
                    <CellObjectCenter>
                        <Yes></Yes>
                    </CellObjectCenter>
                    <CellObjectCenter>
                        <No></No>
                    </CellObjectCenter>
                </CRow>
                <CRowGrey>
                    <CellObject>
                        <LeftHeaderTitleText>
                        {SIDE_HEADING_3}
                        </LeftHeaderTitleText>
                    </CellObject>
                    <CellObjectCenter>
                        <Yes></Yes>
                    </CellObjectCenter>
                    <CellObjectCenter>
                        <Yes></Yes>
                    </CellObjectCenter>
                    <CellObjectCenter>
                        <No></No>
                    </CellObjectCenter>
                    <CellObjectCenter>
                        <No></No>
                    </CellObjectCenter>
                </CRowGrey>
                <CRow >
                    <CellObject>
                        <LeftHeaderTitleText>
                        {SIDE_HEADING_4}
                        </LeftHeaderTitleText>
                    </CellObject>
                    <CellObjectCenter>
                        <Yes></Yes>
                    </CellObjectCenter>
                    <CellObjectCenter>
                        <No></No>
                    </CellObjectCenter>
                    <CellObjectCenter>
                        <No></No>
                    </CellObjectCenter>
                    <CellObjectCenter>
                        <Yes></Yes>
                    </CellObjectCenter>
                </CRow>
                <CRowGrey>
                    <CellObject>
                        <LeftHeaderTitleText>
                        {SIDE_HEADING_5}
                        </LeftHeaderTitleText>
                    </CellObject>
                    <CellObjectCenter>
                        <Yes></Yes>
                    </CellObjectCenter>
                    <CellObjectCenter>
                        <Yes></Yes>
                    </CellObjectCenter>
                    <CellObjectCenter>
                        <Yes></Yes>
                    </CellObjectCenter>
                    <CellObjectCenter>
                        <Yes></Yes>
                    </CellObjectCenter>
                </CRowGrey>
            </CompareCard>
        </div>
    );
}

function Yes() {
    return (<FaCircleCheck className='yes'></FaCircleCheck>);
}

function No() {
    return (<LuBan className='no'></LuBan>);
}

function HeaderLogoImage({ image }) {
    return (
        <img src={image} alt="Your Image" className='header-logo-image' />
    );
}

function HeaderTitleColumn({ children }) {
    return (
        <div className='header-title-column'>
            {children}
        </div>
    );
}

function LeftHeaderTitleText({ children }) {
    return (
        <div className='left-header-title-text'>
            {children}
        </div>
    );
}

function HeaderTitleImage({ image }) {
    return (
        <img src={image} alt="Your Image" className='header-title-image' />
    );
}

function HeaderTitleText({ children }) {
    return (
        <div className='header-title-text'>
            {children}
        </div>
    );
}

function CRow({ children }) {
    return (
        <div className='c-row'>
            {children}
        </div>
    );
}

function CRowGrey({ children }) {
    return (
        <div className='c-row-grey'>
            {children}
        </div>
    );
}

function CellObject({ children }) {
    return (
        <div className='cell-object'>
            {children}
        </div>
    );
}

function CellObjectCenter({ children }) {
    return (
        <div className='cell-object-center'>
            {children}
        </div>
    );
}

function CompareCard({ children }) {
    return (
        <div className="compare-card">
            {children}
        </div>
    );
}

export default CompareChart;
