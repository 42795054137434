import React, { useEffect } from 'react';
import '../styles/SmpForMenPage/SmpForMenPage.css';
import ConsultationForm from '../components/HomePage/ConsultationForm';
import Faq from '../components/HomePage/Faq';
import Footer from '../components/HomePage/Footer';
import Navbar from '../components/HomePage/Navbar';
import Home from '../components/SmpForMenPage/Home';
import TreatmentsForMen from '../components/SmpForMenPage/TreatmentsForMen';
import CompareChart from '../components/HomePage/CompareChart';
import { useSelector, useDispatch } from 'react-redux';
import { selectShowDialog, setShowDialog } from '../state/slices'
import { selectDialogTitle, setDialogTitle } from '../state/slices'
import { selectDialogDesc, setDialogDesc } from '../state/slices'
import { selectShowLoading } from '../state/slices'
import FloatingWhatsAppButton from '../components/FloatingWhatsAppButton';

function SmpForMenPage() {
  const showDialog = useSelector(selectShowDialog);
  const showLoading = useSelector(selectShowLoading);
  const dialogTitle = useSelector(selectDialogTitle);
  const dialogDesc = useSelector(selectDialogDesc);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (!showLoading && !showDialog) {
  //     window.scrollTo(0, 0);
  //   }
  // });

  return (
    <div style={{ backgroundColor: "#fafafa", scrollbarWidth: 'none' }}>
      {/* <Navbar></Navbar> */}
      <div className='sfm-space-between-navbar'></div>
      <Home></Home>
      {/* <About></About> */}
      <TreatmentsForMen></TreatmentsForMen>
      <ConsultationForm></ConsultationForm>
      {/* <WhyNumber1></WhyNumber1> */}
      {/* <CustomerCards></CustomerCards> */}
      <CompareChart></CompareChart>
      <Faq></Faq>
      <Footer></Footer>
      <FloatingWhatsAppButton></FloatingWhatsAppButton>
      <Navbar></Navbar>
      {showLoading && (<div className="dialog-overlay">
        <div className="loading">
          <div className="spinner"></div>
        </div>
      </div>)}
      {showDialog && (<div className="dialog-overlay">
        <div className="dialog">
          <p style={{ fontWeight: 'bold' }}>{dialogTitle}</p>
          <p>{dialogDesc}</p>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <button style={{ backgroundColor: '#023273', color: 'white' }} onClick={() => {
              dispatch(setShowDialog(false));
            }}>Ok</button>
          </div>
        </div>
      </div>)}
    </div>
  );
}

export default SmpForMenPage;
