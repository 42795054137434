import React, { useState, useEffect } from 'react';
import '../styles/PrivacyPolicyPage/PrivacyPolicyPage.css';
import Footer from '../components/HomePage/Footer';
import Navbar from '../components/HomePage/Navbar';
import { ChevronUp, ChevronDown } from 'lucide-react';
import { useNavigate } from "react-router-dom";

import { FAQ_TILE_10_DESCRIPTION, FAQ_TILE_10_HEADING, FAQ_TILE_1_DESCRIPTION, FAQ_TILE_1_HEADING, FAQ_TILE_2_DESCRIPTION, FAQ_TILE_2_HEADING, FAQ_TILE_3_DESCRIPTION, FAQ_TILE_3_HEADING, FAQ_TILE_4_DESCRIPTION, FAQ_TILE_4_HEADING, FAQ_TILE_5_DESCRIPTION, FAQ_TILE_5_HEADING, FAQ_TILE_6_DESCRIPTION, FAQ_TILE_6_HEADING, FAQ_TILE_7_DESCRIPTION, FAQ_TILE_7_HEADING, FAQ_TILE_8_DESCRIPTION, FAQ_TILE_8_HEADING, FAQ_TILE_9_DESCRIPTION, FAQ_TILE_9_HEADING } from '../constants';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { db, collection, getDocs, query, orderBy } from '../firebase';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import FloatingWhatsAppButton from '../components/FloatingWhatsAppButton';

const BLOGS_PER_PAGE = 9;

function PrivacyPolicyPage() {
    const [blogs, setBlogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);

    const { width } = useWindowDimensions();
    const isDesktop = width >= 1025;
    const isTablet = width >= 768 && width <= 1024;
    const isMobile = width <= 767;

    function reValue({ desktop, tablet, mobile }) {
        if (isDesktop) {
            return desktop;
        }
        if (isTablet) {
            return tablet;
        }
        if (isMobile) {
            return mobile;
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchBlogs = async () => {
            try {
                const q = query(collection(db, 'blogs'), orderBy('createdAt', 'desc'));
                const querySnapshot = await getDocs(q);
                const blogData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setBlogs(blogData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching blogs: ", error);
            }
        };

        fetchBlogs();
    }, []);
    return (
        <div style={{ backgroundColor: "#fafafa", scrollbarWidth: 'none' }}>
            <div className='space-between-navbar'></div>
            {/* <Blog blogs={blogs} currentPage={currentPage} setCurrentPage={setCurrentPage} loading={loading}></Blog> */}
            <div style={reValue({
                desktop: { display: 'flex', flexDirection: 'row', justifyContent: 'center' },
                tablet: { display: 'flex', flexDirection: 'row', justifyContent: 'center' },
                mobile: { display: 'flex', flexDirection: 'row', justifyContent: 'center' },
            })}>
                <div style={reValue({
                    desktop: { width: '60%' },
                    tablet: { width: '90%' },
                    mobile: { width: '90%' },
                })}>
                    <div style={reValue({
                        desktop: { display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '130px 0px 60px 0px' },
                        tablet: { display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '130px 0px 60px 0px' },
                        mobile: { display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '130px 0px 60px 0px' },
                    })}>
                        <div className='be-blog-big-text'>
                            Privacy Policy
                        </div>
                    </div>
                    <div style={reValue({
                        desktop: { fontWeight: 'bold' },
                        tablet: { fontWeight: 'bold' },
                        mobile: { fontWeight: 'bold' },
                    })}>1. WHO WE ARE</div>
                    <p style={reValue({
                        desktop: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        tablet: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        mobile: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                    })}>
                        <p></p>
                        This privacy policy <b>(“Privacy Policy”)</b> explains the data protection practices of M/s MICRO INC. SMP. Where we refer to <b>"MIS"</b> or <b>“us”</b> or <b>"we"</b> or <b>“our”</b>, we mean MICRO INC. SMP using your data in accordance with this Privacy Policy.
                        If you live outside the European Region, we provide our services to you under this Privacy Policy. This service is not available to people residing in European Region and should NOT be used by people in the European Region.
                    </p>
                    <br></br>
                    <br></br>
                    <div style={reValue({
                        desktop: { fontWeight: 'bold' },
                        tablet: { fontWeight: 'bold' },
                        mobile: { fontWeight: 'bold' },
                    })}>
                        2. DEFINITIONS
                    </div>
                    <p style={reValue({
                        desktop: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        tablet: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        mobile: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                    })}>
                        <p></p>
                        In this Privacy Policy, unless the context otherwise requires <b>‘Personal Information’</b> means any information/data that relates to a natural person which, either directly or indirectly, in combination with other information available or likely to be available with MIS, is capable of identifying such person including their geo-location information.
                    </p>
                    <br></br>
                    <br></br>
                    <div style={reValue({
                        desktop: { fontWeight: 'bold' },
                        tablet: { fontWeight: 'bold' },
                        mobile: { fontWeight: 'bold' },
                    })}>
                        3. PURPOSE AND SCOPE
                    </div>
                    <p style={reValue({
                        desktop: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        tablet: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        mobile: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                    })}>
                        <p></p>
                        We, are committed to protecting the privacy of the information provider (<b>"you"</b> / <b>"your"</b> / <b>"yourself"</b>). We consider privacy and protection of your Personal Information to be of high importance. Our practices and procedures in relation to the collection and use of your Personal Information has been set-out below in this Privacy Policy. This Privacy Policy will familiarize you with the manner in which MIS may collect, receive, use, share, transfer and disclose your Personal Information. This Privacy Policy forms an integral part of our <a href='/termsandconditions/'>Terms & Conditions</a> of Use to use our Website (<b>“Terms of Use”</b>).
                        <br></br>
                        <br></br>
                        MIS reserves the right, at its discretion, to change, modify, amend, add or remove portions of this <b>Privacy Policy</b> at any time without notice. We recommend that you review this Privacy Policy periodically to ensure that you are aware of the current privacy practices of MIS. By visiting our <a href='/'>website</a> (<b>“Website”</b>), you agree to be bound by the terms and conditions of this Privacy Policy (which includes the Terms of Use).
                        <br></br>
                        <br></br>
                        This Privacy Policy shall be construed to be provided in compliance with the Information Technology Act 2000 (<b>“IT Act”</b>) and applicable rules made thereunder, as amended from time to time, and shall be read with the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules 2011 (<b>“SPDI Rules”</b>), as applicable.
                    </p>
                    <br></br>
                    <br></br>
                    <div style={reValue({
                        desktop: { fontWeight: 'bold' },
                        tablet: { fontWeight: 'bold' },
                        mobile: { fontWeight: 'bold' },
                    })}>
                        4. A SPECIAL NOTE TO CHILDREN UNDER 18 YEARS OF AGE
                    </div>
                    <p style={reValue({
                        desktop: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        tablet: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        mobile: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                    })}>
                        <p></p>
                        YOU MUST BE 18 YEARS OF AGE OR OLDER TO REGISTER WITH OUR SITE. PLEASE DO NOT REGISTER OR USE THE SITE IF YOU ARE UNDER 18 YEARS OF AGE.
                        Our site and services are not directed to children under the age of 18. We do not knowingly collect Personal Information from children under the age of 18. If a user identifies him or herself as a child under the age of 18, we will not collect, store, or use any Personal Information. If we receive Personal Information that we discover was provided by a child under the age of 18, we will promptly destroy such information.
                    </p>
                    <br></br>
                    <br></br>
                    <div style={reValue({
                        desktop: { fontWeight: 'bold' },
                        tablet: { fontWeight: 'bold' },
                        mobile: { fontWeight: 'bold' },
                    })}>
                        5. HOW WE COLLECT YOUR PERSONAL INFORMATION
                    </div>
                    <br></br>
                    <div style={reValue({
                        desktop: { fontWeight: 'bold', margin: '0px 0px 10px 30px' },
                        tablet: { fontWeight: 'bold', margin: '0px 0px 10px 30px' },
                        mobile: { fontWeight: 'bold', margin: '0px 0px 10px 30px' },
                    })}>
                        (a) From you
                    </div>
                    <p style={reValue({
                        desktop: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        tablet: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        mobile: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                    })}>
                        We may collect and process Personal Information relating to you by you providing such Personal Information by filling in forms on our site or by you contacting us. This includes, but is not limited to, Personal Information provided at the time of registering to use (or become a member of) our site, subscribing to any services (e.g. newsletters), or requesting services. Such Personal Information may include but is not limited to:
                        <br></br><div style={{ marginLeft: '50px' }}>i. name, email address, mobile/telephone number, date of birth, address, country of residence, marital status etc;</div>
                        <div style={{ marginLeft: '50px' }}>ii. your credit, or debit card or other payment information including bank details;</div>
                        <div style={{ marginLeft: '50px' }}>iii. your user id and password and other security related information for accessing the Website;</div>
                        <div style={{ marginLeft: '50px' }}>iv. any other Personal Information provided by you while accessing our Website or when you contact our customer care team;</div>
                        <div style={{ marginLeft: '50px' }}>v. We may also collect Personal Information about you from third parties who provide us with additional publicly available information about you;</div>
                        <div style={{ marginLeft: '50px' }}>vi. If you use a mobile phone for browsing our Website, we may collect your IP address or other device identifier, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform, and other portable device information.</div>
                        <br></br>
                        You represent that the Personal Information you provide from time to time is and shall be correct, current and updated and you have all the rights, permissions and consents to provide such information.
                    </p>
                    <div style={reValue({
                        desktop: { fontWeight: 'bold', margin: '15px 0px 0px 30px' },
                        tablet: { fontWeight: 'bold', margin: '15px 0px 0px 30px' },
                        mobile: { fontWeight: 'bold', margin: '15px 0px 0px 30px' },
                    })}>
                        (b) By using cookies
                    </div>
                    <br></br>
                    <p style={reValue({
                        desktop: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        tablet: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        mobile: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                    })}>
                        In addition, we may use ‘cookies’ to collect information relating to your general internet usage. This information may include IP-addresses, browser version, number of visits and similar such information relating to your navigation of the internet and our site. A ‘cookie’ is a small text file that is placed on your computer’s hard drive. Cookies help us to improve our site and to deliver a better and more personalised service, for instance by storing information about your preferences and allowing us to recognise you when you return to our site.
                        <br></br>
                        <br></br>
                        You may refuse to accept cookies by activating settings on your internet browser. However, please note that if you select such settings you may be unable to access certain parts of our site.
                    </p>
                    <br></br>
                    <br></br>
                    <div style={reValue({
                        desktop: { fontWeight: 'bold' },
                        tablet: { fontWeight: 'bold' },
                        mobile: { fontWeight: 'bold' },
                    })}>
                        6. TRANSMISSION OF YOUR PERSONAL INFORMATION VIA THE INTERNET
                    </div>
                    <p style={reValue({
                        desktop: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        tablet: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        mobile: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                    })}>
                        <p></p>
                        Unfortunately, the transmission of information via the internet is not completely secure. Any transmission is therefore at your own risk. Once we have received your Personal Information, we will use strict procedures and security features to try to prevent unauthorised access. However, we cannot guarantee that any Personal Information you submit to us will be free from unauthorized third party intrusion.
                        <br></br>
                        <br></br>
                        Where we have given you (or where you have chosen) a password which enables you to access certain parts of our site, you are responsible for keeping this password confidential. We ask you not to share the password with anyone.
                    </p>
                    <br></br>
                    <br></br>
                    <div style={reValue({
                        desktop: { fontWeight: 'bold' },
                        tablet: { fontWeight: 'bold' },
                        mobile: { fontWeight: 'bold' },
                    })}>
                        7. WHERE YOUR PERSONAL INFORMATION IS KEPT OR TRANSFERRED
                    </div>
                    <p style={reValue({
                        desktop: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        tablet: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        mobile: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                    })}>
                        <p></p>
                        The Personal Information that we collect may be transferred to, and stored at, a destination outside the Territory, for instance if any of our servers from time to time are located outside the Territory or if one of our service providers or suppliers is located in a country outside the Territory or if we share your Personal Information (in accordance with the ‘With whom we share your Personal Information’ section below).
                        <br></br>
                        <br></br>
                        Where any of those destinations are countries that do not have data protection laws as stringent as those to which we are subject, we will take all steps necessary to ensure that your Personal Information is only transferred lawfully out of the Territory, and used in accordance with this Privacy Policy.
                        <br></br>
                        <br></br>
                        You expressly consent to the transfer of your Personal Information to, and the collection and processing of such Personal Information in a destination outside the Territory where we or our vendors operate. In giving this consent, you acknowledge that you understand that the laws on holding, processing, using and transferring Personal Information in those territories may vary and be less protective of your privacy than laws of your state or country
                    </p>
                    <br></br>
                    <br></br>
                    <div style={reValue({
                        desktop: { fontWeight: 'bold' },
                        tablet: { fontWeight: 'bold' },
                        mobile: { fontWeight: 'bold' },
                    })}>
                        8. HOW WE USE YOUR PERSONAL INFORMATION
                    </div>
                    <p style={reValue({
                        desktop: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        tablet: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        mobile: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                    })}>
                        <p></p>
                        We use the Personal Information collected from our site in the following ways:
                        <br></br>
                        <strong>•</strong> to establish your identity
                        <br></br>
                        <strong>•</strong> to ensure that content from our site is presented in the most effective manner for you and for your computer and tailors out site to your needs. Personal Information on our website may be used to compile aggregate statistics and carry out analysis in order to help us create website content and navigation that is most relevant and user-friendly to you.
                        <br></br>
                        <strong>•</strong> to provide you with information, products or services that you have requested from us (or third parties, where appropriate) or which, based on your musical preferences or use, we feel may interest you.
                        <br></br>
                        <strong>•</strong> to deal with and respond to your requests, enquiries and complaints, customer services and related activities and also for fulfilling your requests for information regarding our service.
                        <br></br>
                        <strong>•</strong> to carry out our obligations arising from any contracts entered into between you and us.
                        <br></br>
                        <strong>•</strong> to allow you to participate in interactive features of our service, when you choose to do so.
                        <br></br>
                        <strong>•</strong> to enable credit checks – such as analysing, verifying and/or checking your credit, payment and/or status in relation to your ability to use any e-commerce-related services (if any) on our website.
                        <br></br>
                        <strong>•</strong> to notify you about changes to our service.
                        <br></br>
                        <strong>•</strong> to conduct survey about how satisfied our customers are and for improving our performance and provide better services to the customer;
                        <br></br>
                        <strong>•</strong> responding to judicial process and provide information to law enforcement agencies or in connection with an investigation on matters related to public safety, as permitted by law.
                    </p>
                    <br></br>
                    <br></br>
                    <div style={reValue({
                        desktop: { fontWeight: 'bold' },
                        tablet: { fontWeight: 'bold' },
                        mobile: { fontWeight: 'bold' },
                    })}>
                        9. WITH WHOM WE SHARE YOUR PERSONAL INFORMATION
                    </div>
                    <p></p>
                    <p style={reValue({
                        desktop: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        tablet: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        mobile: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                    })}>
                        Subject to the other terms of this Privacy Policy, we may share your Personal Information with the following:
                        <br></br>
                        <br></br>
                        <strong> (a) Group companies and third party service providers </strong>
                        <br></br>
                        <p></p>
                        We may share your Personal Information with our affiliated or group companies worldwide and third party service providers**.
                        <br></br>
                        <p></p>
                        <strong> (b) Other third parties**</strong>
                        <br></br>
                        <p></p>
                        We may transfer, or assign any data, including Personal Information, to third parties, only in connection with the sale, merger, consolidation, change in control, transfer of substantial assets, reorganization or liquidation of MIS, or any part of it.
                        <br></br>
                        <p></p>
                        <strong> (c) In special circumstances </strong>
                        <br></br>
                        <p></p>
                        We may disclose Personal Information in the following cases:
                        <p></p>
                        <div style={{ marginLeft: '50px' }}>i) to comply with any legal process whether local or foreign;</div>
                        <div style={{ marginLeft: '50px' }}>ii) to respond to requests from public and government authorities, including public and government authorities outside your country of residence; </div>
                        <div style={{ marginLeft: '50px' }}>iii) to enforce our Terms & Conditions of Use of Website and policies;</div>
                        <div style={{ marginLeft: '50px' }}>iv) when MIS, in its sole discretion, deems it necessary in order to protect its rights or the rights of others;</div>
                        <div style={{ marginLeft: '50px' }}>v) to protect our rights, property and privacy and/or that of our affiliates, you or others;</div>
                        <div style={{ marginLeft: '50px' }}>vi) when we have reason to believe that disclosing this Personal Information is necessary to identify, contact or bring legal action against someone who may be causing injury to or interference (either intentionally or unintentionally) with the rights or property of those described above in paragraph (a) or (b); and</div>
                        <div style={{ marginLeft: '50px' }}>vii) when we believe in good faith that the law requires us to do so.</div>
                        <p></p>
                        **Note:  Your Personal Information will not be disclosed by us to third parties without your consent for marketing or promotional purposes, save for, our internal purposes of marketing and promoting.
                    </p>
                    <br></br>
                    <br></br>
                    <div style={reValue({
                        desktop: { fontWeight: 'bold' },
                        tablet: { fontWeight: 'bold' },
                        mobile: { fontWeight: 'bold' },
                    })}>
                        10. PERIOD OF RETENTION
                    </div>
                    <br></br>
                    <p style={reValue({
                        desktop: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        tablet: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        mobile: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                    })}>
                        Your Personal Information which we collect will be retained for a period necessary to attain the specified purposes.
                    </p>
                    <br></br>
                    <br></br>
                    <div style={reValue({
                        desktop: { fontWeight: 'bold' },
                        tablet: { fontWeight: 'bold' },
                        mobile: { fontWeight: 'bold' },
                    })}>
                        11. YOUR RIGHT TO OBTAIN, CHANGE OR REMOVE YOUR PERSONAL INFORMATION
                    </div>
                    <p style={reValue({
                        desktop: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        tablet: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        mobile: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                    })}>
                        <p></p>
                        You can opt out of receiving any further messages from MIS or anyone with whom we share your Personal Information (as described above) at any time by following the unsubscribe instructions in any message sent to you.  If you wish to unsubscribe from messages we send you, you can also send an email to info@microincsmp.com
                        <br></br>
                        <p></p>
                        You have a right to access or correct the Personal Information we hold about you. To obtain a copy of the Personal Information we hold about you, please write to us at our registered address provided at the beginning of this Privacy Policy.
                    </p>
                    <br></br>
                    <br></br>
                    <div style={reValue({
                        desktop: { fontWeight: 'bold' },
                        tablet: { fontWeight: 'bold' },
                        mobile: { fontWeight: 'bold' },
                    })}>
                        12. OTHER WEBSITES
                    </div>
                    <p style={reValue({
                        desktop: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        tablet: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        mobile: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                    })}>
                        <p></p>
                        Whilst we endeavour to adhere to our strictest guidelines and select only reputable partners in providing the service, our Privacy Policy does not cover any information that you provide directly to third parties (including but not limited to online merchants sites and other hyperlinked websites). We advise you to consult with the relevant parties before providing any such information.
                        Our website may contain links to other websites which are outside our control and are not covered by this Privacy Policy. If you access other sites using the links provided, the operators of these sites may collect and process information from you in a way which is different to how we do so.
                    </p>
                    <br></br>
                    <br></br>
                    <div style={reValue({
                        desktop: { fontWeight: 'bold' },
                        tablet: { fontWeight: 'bold' },
                        mobile: { fontWeight: 'bold' },
                    })}>
                        13. CHANGES TO OUR PRIVACY POLICY
                    </div>
                    <p style={reValue({
                        desktop: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        tablet: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        mobile: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                    })}>
                        <p></p>
                        By using this Website, you consent to the collection and use of your Personal Information as described above. We are sensitive to users’ privacy issues and recognize the need to update this Privacy Policy as new features and business services are offered by us. Accordingly, we may modify, alter or otherwise update this Privacy Policy at any time. We will indicate any changes by updating the date at the top of the Privacy policy. We encourage you to review this Privacy Policy from time to time.
                    </p>
                    <br></br>
                    <br></br>
                    <div style={reValue({
                        desktop: { fontWeight: 'bold' },
                        tablet: { fontWeight: 'bold' },
                        mobile: { fontWeight: 'bold' },
                    })}>
                        14. DISPUTES
                    </div>
                    <p style={reValue({
                        desktop: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        tablet: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                        mobile: { fontSize: '15px', lineHeight: '24px', margin: '0px 0px 0px 30px' },
                    })}>
                        <p></p>
                        Any dispute, controversy or claim directly or indirectly caused by, arising out of or relating to this Privacy Policy will be governed by the laws of India and will be subject to the exclusive jurisdiction of the Courts in Mumbai, India
                    </p>
                    <div style={{ height: '200px' }}></div>
                </div>
            </div>
            <Footer></Footer>
            <FloatingWhatsAppButton></FloatingWhatsAppButton>
            <Navbar></Navbar>
        </div>
    );
}


function Blog({ blogs, currentPage, setCurrentPage, loading }) {
    return (
        <div>
            <BlogMainContainer blogs={blogs} currentPage={currentPage} setCurrentPage={setCurrentPage} loading={loading}></BlogMainContainer>
        </div>
    );
}


function BlogMainContainer({ blogs, currentPage, setCurrentPage, loading }) {
    const { width, height } = useWindowDimensions();

    const blogWrap = {
        display: 'flex',
        flexWrap: 'wrap',
        // gap: '30px', /* Adjust the gap between items */
        gap: width < 911 ? '7vw' : '2.1vw',
        margin: '',
        // alignItems: 'space-between',
        justifyContent: 'center',
        // padding: '10px',
        // border: '1px solid #ccc'
    };

    if (width < 911) {
        blogWrap.gap = '3vw';
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };


    const startIndex = (currentPage - 1) * BLOGS_PER_PAGE;
    const selectedBlogs = blogs.slice(startIndex, startIndex + BLOGS_PER_PAGE);

    return (
        <div className='be-blog-main-container'>
            <div className='be-blog-row'>
                <BlogMainHeading></BlogMainHeading>
                <DottedLine></DottedLine>
                {/* <div className='be-blog-right-side'>
                    <BlogTiles></BlogTiles>
                </div> */}
                <div style={{
                    // backgroundColor: 'black',
                    height: '80px',
                    width: '30px',
                }}>

                </div>
                <div style={blogWrap}>
                    {/* <BlogCard></BlogCard>
                    <BlogCard></BlogCard>
                    <BlogCard></BlogCard>
                    <BlogCard></BlogCard>
                    <BlogCard></BlogCard>
                    <BlogCard></BlogCard> */}
                    {/* {selectedBlogs.length > 0 ? (
                        selectedBlogs.map(blog => (
                            <BlogCard blog={blog}></BlogCard>

                        ))
                    ) : (
                        <p>No blogs available</p>
                    )} */}
                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0px 0px 100px 0px' }}>
                            <CircularProgress />
                        </Box>
                    ) : selectedBlogs.length > 0 ? (
                        selectedBlogs.map((blog, index) => (
                            <BlogCard key={index} blog={blog} />
                        ))
                    ) : (
                        <p>No blogs available</p>
                    )}
                </div>
                {/* <div className="pagination">
                    <button onClick={handlePrevPage} disabled={currentPage === 1}>
                        Previous
                    </button>
                    <button onClick={handleNextPage} disabled={startIndex + BLOGS_PER_PAGE >= blogs.length}>
                        Next
                    </button>
                </div> */}
                {(!loading && selectedBlogs.length > 0) ? (<div className="pagination" style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
                    <button
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                        style={{
                            padding: '10px 20px',
                            margin: '0 10px',
                            backgroundColor: '#023273',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            opacity: currentPage === 1 ? 0.5 : 1,
                            pointerEvents: currentPage === 1 ? 'none' : 'auto'
                        }}
                    >
                        Previous
                    </button>
                    <button
                        onClick={handleNextPage}
                        disabled={startIndex + BLOGS_PER_PAGE >= blogs.length}
                        style={{
                            padding: '10px 20px',
                            margin: '0 10px',
                            backgroundColor: '#023273',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            opacity: startIndex + BLOGS_PER_PAGE >= blogs.length ? 0.5 : 1,
                            pointerEvents: startIndex + BLOGS_PER_PAGE >= blogs.length ? 'none' : 'auto'
                        }}
                    >
                        Next
                    </button>
                </div>) : (<div></div>)}
            </div>
        </div>
    );
}

function BlogCard({ blog }) {
    const navigate = useNavigate();
    const { width } = useWindowDimensions();
    console.log(`the width here is: ${width}`);

    // Define the styles for different window widths
    const cardStyleDesktop = {
        backgroundColor: 'white',
        display: 'inline-block',
        margin: '0px',
        borderRadius: '8px',
        boxShadow: '0 0 20px #c9c9c95c',
        width: '28.5vw',
        height: '25vw',
        cursor: 'pointer',
        overflow: 'hidden', // Ensure the image doesn't overflow
    };

    const cardStyleTablet = {
        backgroundColor: 'white',
        display: 'inline-block',
        margin: '0px',
        borderRadius: '8px',
        boxShadow: '0 0 20px #c9c9c95c',
        width: '38.8vw',
        height: '35vw',
        cursor: 'pointer',
        overflow: 'hidden',
    };

    const cardStyleMobile = {
        backgroundColor: 'white',
        display: 'inline-block',
        margin: '0px',
        borderRadius: '8px',
        boxShadow: '0 0 20px #c9c9c95c',
        width: '90vw',
        height: '75vw',
        cursor: 'pointer',
        overflow: 'hidden',
    };

    // =================================================================================================================

    const titleStyleDesktop = {
        padding: '10px 20px 10px 20px',
        fontSize: '1.2vw', // Font size for the title
        fontWeight: 'bold', // Bold title
        color: '#333', // Dark color for contrast
        textAlign: 'left', // Center align text
        margin: '0', // Ensure no extra margin
    };

    const titleStyleTablet = {
        padding: '10px 20px 10px 20px',
        fontSize: '1.6vw', // Font size for the title
        fontWeight: 'bold', // Bold title
        color: '#333', // Dark color for contrast
        textAlign: 'left', // Center align text
        margin: '0', // Ensure no extra margin
    };

    const titleStyleMobile = {
        padding: '10px 20px 10px 20px',
        fontSize: '4vw', // Font size for the title
        fontWeight: 'bold', // Bold title
        color: '#333', // Dark color for contrast
        textAlign: 'left', // Center align text
        margin: '0', // Ensure no extra margin
    };

    // =================================================================================================================

    const dateTextStyleDesktop = {
        margin: '10px 0',
        fontSize: '0.9vw', // Smaller font size for date
        color: '#777', // Lighter color for date
        textAlign: 'left', // Center align text
        padding: '10px 2px 0px 20px',
    }

    const dateTextStyleTablet = {
        margin: '10px 0',
        fontSize: '1.25vw', // Smaller font size for date
        color: '#777', // Lighter color for date
        textAlign: 'left', // Center align text
        padding: '10px 2px 0px 20px',
    }

    const dateTextStyleMobile = {
        margin: '10px 0',
        fontSize: '2.6vw', // Smaller font size for date
        color: '#777', // Lighter color for date
        textAlign: 'left', // Center align text
        padding: '10px 2px 0px 20px',
    }

    // =================================================================================================================

    // Determine which style to use
    let cardStyle = cardStyleDesktop;
    let titleStyle = titleStyleDesktop;
    let dateTextStyle = dateTextStyleDesktop;

    if (width < 1025) {
        cardStyle = cardStyleTablet;
        if (width < 911) {
            cardStyle.width = '38.4vw'; // Fix the width adjustment for tablets
        }
        titleStyle = titleStyleTablet
        dateTextStyle = dateTextStyleTablet;
    }
    if (width < 767) {
        cardStyle = cardStyleMobile;
        titleStyle = titleStyleMobile;
        dateTextStyle = dateTextStyleMobile;
    }

    return (
        <div
            style={cardStyle}
            onClick={() => {
                window.scrollTo(0, 0);
                navigate(`/blog/${blog.slug}`);
            }}
        >
            <img
                style={{
                    width: '100%', // Fit to the width of the card
                    height: '60%', // Adjust height as needed
                    objectFit: 'cover', // Ensure image covers the card area
                }}
                src={blog.imageUrl}
                alt={blog.title}
            />
            <p style={dateTextStyle}>
                {new Date(blog.createdAt.seconds * 1000).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).toUpperCase()}
            </p>
            <div style={titleStyle}>
                {blog.title}
            </div>
        </div>
    );
}



function BlogMainHeading() {
    return (
        <div className='be-blog-main-heading'>
            <div className='be-blog-big-text'>
                Blog
            </div>
            <div className='be-blog-small-text'>

            </div>
        </div>
    );
}

function DottedLine() {
    return (
        <div className='be-dotted-line-row'>
            <div className='be-dotted-line'></div>
        </div>
    );
}

function BlogTiles() {
    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [visible3, setVisible3] = useState(false);
    const [visible4, setVisible4] = useState(false);
    const [visible5, setVisible5] = useState(false);
    // ................................................
    const [visible6, setVisible6] = useState(false);
    const [visible7, setVisible7] = useState(false);
    const [visible8, setVisible8] = useState(false);
    const [visible9, setVisible9] = useState(false);
    const [visible10, setVisible10] = useState(false);

    return (
        <div className='be-expandable-tiles-column'>
            <ExpandableTile visible={visible1} onClick={(event) => {
                event.preventDefault();
                setVisible1(!visible1);
                if (visible1 === false) {
                    // setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
            }} title={FAQ_TILE_1_HEADING} content={FAQ_TILE_1_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible2(!visible2);
                if (visible2 === false) {
                    setVisible1(false);
                    // setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
            }} visible={visible2} title={FAQ_TILE_2_HEADING} content={FAQ_TILE_2_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible3(!visible3);
                if (visible3 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    // setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
                // <img className='home-video-component' src={SMP_FOR_SCARS_BANNER_IMAGE}></img>
            }} visible={visible3} title={FAQ_TILE_3_HEADING} content={FAQ_TILE_3_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible4(!visible4);
                if (visible4 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    // setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
            }} visible={visible4} title={FAQ_TILE_4_HEADING} content={FAQ_TILE_4_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible5(!visible5);
                if (visible5 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    // setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
            }} visible={visible5} title={FAQ_TILE_5_HEADING} content={FAQ_TILE_5_DESCRIPTION}></ExpandableTile>
            {/* ----------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
            <ExpandableTile visible={visible6} onClick={(event) => {
                event.preventDefault();
                setVisible6(!visible6);
                if (visible6 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    // setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
            }} title={FAQ_TILE_6_HEADING} content={FAQ_TILE_6_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible7(!visible7);
                if (visible7 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    // setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
            }} visible={visible7} title={FAQ_TILE_7_HEADING} content={FAQ_TILE_7_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible8(!visible8);
                if (visible8 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    // setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
                // <img className='home-video-component' src={SMP_FOR_SCARS_BANNER_IMAGE}></img>
            }} visible={visible8} title={FAQ_TILE_8_HEADING} content={FAQ_TILE_8_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible9(!visible9);
                if (visible9 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    // setVisible9(false);
                    setVisible10(false);
                }
            }} visible={visible9} title={FAQ_TILE_9_HEADING} content={FAQ_TILE_9_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible10(!visible10);
                if (visible10 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    // setVisible10(false);
                }
            }} visible={visible10} title={FAQ_TILE_10_HEADING} content={FAQ_TILE_10_DESCRIPTION}></ExpandableTile>

        </div>
    );
}

function ExpandableTile({ visible, title, content, onClick }) {
    return (
        <div className='be-expandable-tile'>
            <div className='be-expandable-tile-heading'>
                <div className='be-expandable-tile-heading-text' onClick={onClick}>
                    {title}
                </div>
                <div>
                    {visible ? <ChevronUp /> : <ChevronDown />}
                </div>
            </div>
            <div style={{
                display: `${visible ? 'block' : 'none'}`,
                overflow: 'hidden',
                margin: '20px 0px 0px 0px',
                width: '93%',
                lineHeight: '24px'
            }}>
                {visible && content}
            </div>
        </div>
    );
}

export default PrivacyPolicyPage;