import React, { useState, useEffect } from 'react';
import '../styles/BlogPage/BlogPage.css';
import Footer from '../components/HomePage/Footer';
import Navbar from '../components/HomePage/Navbar';
import { ChevronUp, ChevronDown } from 'lucide-react';
import { useNavigate } from "react-router-dom";

import { FAQ_TILE_10_DESCRIPTION, FAQ_TILE_10_HEADING, FAQ_TILE_1_DESCRIPTION, FAQ_TILE_1_HEADING, FAQ_TILE_2_DESCRIPTION, FAQ_TILE_2_HEADING, FAQ_TILE_3_DESCRIPTION, FAQ_TILE_3_HEADING, FAQ_TILE_4_DESCRIPTION, FAQ_TILE_4_HEADING, FAQ_TILE_5_DESCRIPTION, FAQ_TILE_5_HEADING, FAQ_TILE_6_DESCRIPTION, FAQ_TILE_6_HEADING, FAQ_TILE_7_DESCRIPTION, FAQ_TILE_7_HEADING, FAQ_TILE_8_DESCRIPTION, FAQ_TILE_8_HEADING, FAQ_TILE_9_DESCRIPTION, FAQ_TILE_9_HEADING } from '../constants';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { db, collection, getDocs, query, orderBy } from '../firebase';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import FloatingWhatsAppButton from '../components/FloatingWhatsAppButton';

const BLOGS_PER_PAGE = 9;

function BlogPage() {
    const [blogs, setBlogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchBlogs = async () => {
            try {
                const q = query(collection(db, 'blogs'), orderBy('createdAt', 'desc'));
                const querySnapshot = await getDocs(q);
                const blogData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setBlogs(blogData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching blogs: ", error);
            }
        };

        fetchBlogs();
    }, []);
    return (
        <div style={{ backgroundColor: "#fafafa", scrollbarWidth: 'none' }}>
            <div className='space-between-navbar'></div>
            <Blog blogs={blogs} currentPage={currentPage} setCurrentPage={setCurrentPage} loading={loading}></Blog>
            <Footer></Footer>
            <FloatingWhatsAppButton></FloatingWhatsAppButton>
            <Navbar></Navbar>
        </div>
    );
}


function Blog({ blogs, currentPage, setCurrentPage, loading }) {
    return (
        <div>
            <BlogMainContainer blogs={blogs} currentPage={currentPage} setCurrentPage={setCurrentPage} loading={loading}></BlogMainContainer>
        </div>
    );
}


function BlogMainContainer({ blogs, currentPage, setCurrentPage, loading }) {
    const { width, height } = useWindowDimensions();

    const blogWrap = {
        display: 'flex',
        flexWrap: 'wrap',
        // gap: '30px', /* Adjust the gap between items */
        gap: width < 911 ? '7vw' : '2.1vw',
        margin: '',
        // alignItems: 'space-between',
        justifyContent: 'center',
        // padding: '10px',
        // border: '1px solid #ccc'
    };

    if (width < 911) {
        blogWrap.gap = '3vw';
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };


    const startIndex = (currentPage - 1) * BLOGS_PER_PAGE;
    const selectedBlogs = blogs.slice(startIndex, startIndex + BLOGS_PER_PAGE);

    return (
        <div className='be-blog-main-container'>
            <div className='be-blog-row'>
                <BlogMainHeading></BlogMainHeading>
                <DottedLine></DottedLine>
                {/* <div className='be-blog-right-side'>
                    <BlogTiles></BlogTiles>
                </div> */}
                <div style={{
                    // backgroundColor: 'black',
                    height: '80px',
                    width: '30px',
                }}>

                </div>
                <div style={blogWrap}>
                    {/* <BlogCard></BlogCard>
                    <BlogCard></BlogCard>
                    <BlogCard></BlogCard>
                    <BlogCard></BlogCard>
                    <BlogCard></BlogCard>
                    <BlogCard></BlogCard> */}
                    {/* {selectedBlogs.length > 0 ? (
                        selectedBlogs.map(blog => (
                            <BlogCard blog={blog}></BlogCard>

                        ))
                    ) : (
                        <p>No blogs available</p>
                    )} */}
                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0px 0px 100px 0px' }}>
                            <CircularProgress />
                        </Box>
                    ) : selectedBlogs.length > 0 ? (
                        selectedBlogs.map((blog, index) => (
                            <BlogCard key={index} blog={blog} />
                        ))
                    ) : (
                        <p>No blogs available</p>
                    )}
                </div>
                {/* <div className="pagination">
                    <button onClick={handlePrevPage} disabled={currentPage === 1}>
                        Previous
                    </button>
                    <button onClick={handleNextPage} disabled={startIndex + BLOGS_PER_PAGE >= blogs.length}>
                        Next
                    </button>
                </div> */}
                {(!loading && selectedBlogs.length > 0) ? (<div className="pagination" style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
                    <button
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                        style={{
                            padding: '10px 20px',
                            margin: '0 10px',
                            backgroundColor: '#023273',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            opacity: currentPage === 1 ? 0.5 : 1,
                            pointerEvents: currentPage === 1 ? 'none' : 'auto'
                        }}
                    >
                        Previous
                    </button>
                    <button
                        onClick={handleNextPage}
                        disabled={startIndex + BLOGS_PER_PAGE >= blogs.length}
                        style={{
                            padding: '10px 20px',
                            margin: '0 10px',
                            backgroundColor: '#023273',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            opacity: startIndex + BLOGS_PER_PAGE >= blogs.length ? 0.5 : 1,
                            pointerEvents: startIndex + BLOGS_PER_PAGE >= blogs.length ? 'none' : 'auto'
                        }}
                    >
                        Next
                    </button>
                </div>) : (<div></div>)}
            </div>
        </div>
    );
}

function BlogCard({ blog }) {
    const navigate = useNavigate();
    const { width } = useWindowDimensions();
    console.log(`the width here is: ${width}`);

    // Define the styles for different window widths
    const cardStyleDesktop = {
        backgroundColor: 'white',
        display: 'inline-block',
        margin: '0px',
        borderRadius: '8px',
        boxShadow: '0 0 20px #c9c9c95c',
        width: '28.5vw',
        height: '25vw',
        cursor: 'pointer',
        overflow: 'hidden', // Ensure the image doesn't overflow
    };

    const cardStyleTablet = {
        backgroundColor: 'white',
        display: 'inline-block',
        margin: '0px',
        borderRadius: '8px',
        boxShadow: '0 0 20px #c9c9c95c',
        width: '38.8vw',
        height: '35vw',
        cursor: 'pointer',
        overflow: 'hidden',
    };

    const cardStyleMobile = {
        backgroundColor: 'white',
        display: 'inline-block',
        margin: '0px',
        borderRadius: '8px',
        boxShadow: '0 0 20px #c9c9c95c',
        width: '90vw',
        height: '75vw',
        cursor: 'pointer',
        overflow: 'hidden',
    };

    // =================================================================================================================

    const titleStyleDesktop = {
        padding: '10px 20px 10px 20px',
        fontSize: '1.2vw', // Font size for the title
        fontWeight: 'bold', // Bold title
        color: '#333', // Dark color for contrast
        textAlign: 'left', // Center align text
        margin: '0', // Ensure no extra margin
    };

    const titleStyleTablet = {
        padding: '10px 20px 10px 20px',
        fontSize: '1.6vw', // Font size for the title
        fontWeight: 'bold', // Bold title
        color: '#333', // Dark color for contrast
        textAlign: 'left', // Center align text
        margin: '0', // Ensure no extra margin
    };

    const titleStyleMobile = {
        padding: '10px 20px 10px 20px',
        fontSize: '4vw', // Font size for the title
        fontWeight: 'bold', // Bold title
        color: '#333', // Dark color for contrast
        textAlign: 'left', // Center align text
        margin: '0', // Ensure no extra margin
    };

    // =================================================================================================================

    const dateTextStyleDesktop = {
        margin: '10px 0',
        fontSize: '0.9vw', // Smaller font size for date
        color: '#777', // Lighter color for date
        textAlign: 'left', // Center align text
        padding: '10px 2px 0px 20px',
    }

    const dateTextStyleTablet = {
        margin: '10px 0',
        fontSize: '1.25vw', // Smaller font size for date
        color: '#777', // Lighter color for date
        textAlign: 'left', // Center align text
        padding: '10px 2px 0px 20px',
    }

    const dateTextStyleMobile = {
        margin: '10px 0',
        fontSize: '2.6vw', // Smaller font size for date
        color: '#777', // Lighter color for date
        textAlign: 'left', // Center align text
        padding: '10px 2px 0px 20px',
    }

    // =================================================================================================================

    // Determine which style to use
    let cardStyle = cardStyleDesktop;
    let titleStyle = titleStyleDesktop;
    let dateTextStyle = dateTextStyleDesktop;

    if (width < 1025) {
        cardStyle = cardStyleTablet;
        if (width < 911) {
            cardStyle.width = '38.4vw'; // Fix the width adjustment for tablets
        }
        titleStyle = titleStyleTablet
        dateTextStyle = dateTextStyleTablet;
    }
    if (width < 767) {
        cardStyle = cardStyleMobile;
        titleStyle = titleStyleMobile;
        dateTextStyle = dateTextStyleMobile;
    }

    return (
        <div
            style={cardStyle}
            onClick={() => {
                window.scrollTo(0, 0);
                navigate(`/blog/${blog.slug}`);
            }}
        >
            <img
                style={{
                    width: '100%', // Fit to the width of the card
                    height: '60%', // Adjust height as needed
                    objectFit: 'cover', // Ensure image covers the card area
                }}
                src={blog.imageUrl}
                alt={blog.title}
            />
            <p style={dateTextStyle}>
                {new Date(blog.createdAt.seconds * 1000).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).toUpperCase()}
            </p>
            <div style={titleStyle}>
                {blog.title}
            </div>
        </div>
    );
}



function BlogMainHeading() {
    return (
        <div className='be-blog-main-heading'>
            <div className='be-blog-big-text'>
                Blog
            </div>
            <div className='be-blog-small-text'>

            </div>
        </div>
    );
}

function DottedLine() {
    return (
        <div className='be-dotted-line-row'>
            <div className='be-dotted-line'></div>
        </div>
    );
}

function BlogTiles() {
    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [visible3, setVisible3] = useState(false);
    const [visible4, setVisible4] = useState(false);
    const [visible5, setVisible5] = useState(false);
    // ................................................
    const [visible6, setVisible6] = useState(false);
    const [visible7, setVisible7] = useState(false);
    const [visible8, setVisible8] = useState(false);
    const [visible9, setVisible9] = useState(false);
    const [visible10, setVisible10] = useState(false);

    return (
        <div className='be-expandable-tiles-column'>
            <ExpandableTile visible={visible1} onClick={(event) => {
                event.preventDefault();
                setVisible1(!visible1);
                if (visible1 === false) {
                    // setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
            }} title={FAQ_TILE_1_HEADING} content={FAQ_TILE_1_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible2(!visible2);
                if (visible2 === false) {
                    setVisible1(false);
                    // setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
            }} visible={visible2} title={FAQ_TILE_2_HEADING} content={FAQ_TILE_2_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible3(!visible3);
                if (visible3 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    // setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
                // <img className='home-video-component' src={SMP_FOR_SCARS_BANNER_IMAGE}></img>
            }} visible={visible3} title={FAQ_TILE_3_HEADING} content={FAQ_TILE_3_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible4(!visible4);
                if (visible4 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    // setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
            }} visible={visible4} title={FAQ_TILE_4_HEADING} content={FAQ_TILE_4_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible5(!visible5);
                if (visible5 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    // setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
            }} visible={visible5} title={FAQ_TILE_5_HEADING} content={FAQ_TILE_5_DESCRIPTION}></ExpandableTile>
            {/* ----------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
            <ExpandableTile visible={visible6} onClick={(event) => {
                event.preventDefault();
                setVisible6(!visible6);
                if (visible6 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    // setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
            }} title={FAQ_TILE_6_HEADING} content={FAQ_TILE_6_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible7(!visible7);
                if (visible7 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    // setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
            }} visible={visible7} title={FAQ_TILE_7_HEADING} content={FAQ_TILE_7_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible8(!visible8);
                if (visible8 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    // setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
                // <img className='home-video-component' src={SMP_FOR_SCARS_BANNER_IMAGE}></img>
            }} visible={visible8} title={FAQ_TILE_8_HEADING} content={FAQ_TILE_8_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible9(!visible9);
                if (visible9 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    // setVisible9(false);
                    setVisible10(false);
                }
            }} visible={visible9} title={FAQ_TILE_9_HEADING} content={FAQ_TILE_9_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible10(!visible10);
                if (visible10 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    // setVisible10(false);
                }
            }} visible={visible10} title={FAQ_TILE_10_HEADING} content={FAQ_TILE_10_DESCRIPTION}></ExpandableTile>

        </div>
    );
}

function ExpandableTile({ visible, title, content, onClick }) {
    return (
        <div className='be-expandable-tile'>
            <div className='be-expandable-tile-heading'>
                <div className='be-expandable-tile-heading-text' onClick={onClick}>
                    {title}
                </div>
                <div>
                    {visible ? <ChevronUp /> : <ChevronDown />}
                </div>
            </div>
            <div style={{
                display: `${visible ? 'block' : 'none'}`,
                overflow: 'hidden',
                margin: '20px 0px 0px 0px',
                width: '93%',
                lineHeight: '24px'
            }}>
                {visible && content}
            </div>
        </div>
    );
}

export default BlogPage;