import React, { useRef } from 'react';
import '../styles/TestimonialsHome.css';
import { LuChevronLeftCircle, LuChevronRightCircle } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { SMP_FOR_ALOPECIA_URL_1, SMP_FOR_ALOPECIA_URL_2, SMP_FOR_SCARS_URL_1, SMP_FOR_SCARS_URL_2, TREATMENTS_HEADING_1, TREATMENTS_HEADING_2, SMP_FOR_MEN_CARD_TITLE, SMP_FOR_MEN_CARD_DESCRIPTION, SMP_FOR_MEN_CARD_FOOTER, SMP_FOR_WOMEN_CARD_TITLE, SMP_FOR_WOMEN_CARD_DESCRIPTION, SMP_FOR_WOMEN_CARD_FOOTER, SMP_FOR_ALOPECIA_CARD_TITLE, SMP_FOR_ALOPECIA_CARD_DESCRIPTION, SMP_FOR_ALOPECIA_CARD_FOOTER, SMP_FOR_SCARS_CARD_TITLE, SMP_FOR_SCARS_CARD_DESCRIPTION, SMP_FOR_SCARS_CARD_FOOTER } from '../constants';
import { MdOutlineStar } from "react-icons/md";

function TestimonialsHome() {
    return (
        <div>
            <TestimonialsHomeMainDesktop></TestimonialsHomeMainDesktop>
            <TestimonialsHomeMainTablet></TestimonialsHomeMainTablet>
            <TestimonialsHomeMainMobile></TestimonialsHomeMainMobile>
        </div>
    );
};

function TestimonialsHomeMainDesktop() {
    const navigate = useNavigate();
    const styles = {
        buttonContainer: {
            // backgroundColor: 'red',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItms: 'center',
            fontSize: '30px',
            margin: '0px 5px 0px 0px'
        },
        icon: {
            cursor: 'pointer',
            color: '#c5c5c5'
        },
    };

    const scrollRef = useRef(null);

    const scrollLeft = () => {
        scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    };

    const scrollRight = () => {
        scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    };

    return (
        <div className='testimonials-home-main-desktop'>
            <div className='testimonials-home-head'>
                <div className='testimonials-home-head-column'>
                    {/* <div className='testimonials-home-text'>
                        TESTIMONIALS
                    </div> */}
                    <div className='testimonials-home-text2'>
                        Reviews
                    </div>
                </div>
                <div style={styles.buttonContainer}>
                    <LuChevronLeftCircle onClick={scrollLeft} style={styles.icon} />
                    <div style={{ width: '15px' }}></div>
                    <LuChevronRightCircle onClick={scrollRight} style={styles.icon} />
                </div>
            </div>
            <div ref={scrollRef} className='testimonial-home-cards'>
                <TestimonialCard
                    text1="Nilesh More"
                    text2="It was a wonderful experience with Chetan sir in his studio of Micro Inc. SMP. His experience, Passion and dedicated work is remarkable. Good Hygiene was maintained, pleasant atmosphere. He works with all his energy and enthusiasm and make client comfortable throughout the sessions . The most important thing is client satisfaction, he works till that extent where client gets satisfied from his work, Really Worth for money."
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Vinod Bhagat"
                    text2="I don't usually write reviews, but I felt compelled to share my experience with Micro Inc SMP because of the exceptional quality of his work. The art of Scalp Micropigmentation (SMP) requires skill and finesse, and Chetan Mahajan from Micro Inc SMP is a Master. This procedure has been transformative. He gave me the confident I needed and a new look in life. He's such a perfectionist and took the time to do it right and make sure I'm happy with the result for anyone feeling stuck and looking for a change, I highly recommend Chetan’s Micro Inc SMP. Choosing Chetan Mahajan for your SMP treatment is a decision you won't regret. Forever grateful!"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Clara Thomas"
                    text2="I dealt with hair loss as a woman for the past 3 years. I wasn't confident about how I looked. I tried almost every possible treatment to improve the condition but I wasn't happy. I was nervous at the start of the process but Chetan walked me through, explaining what he will be doing and made me comfortable. It was a painless process. Now my confidence is back and I can style and wear my hair in any way I want too. I can't thank Micro Inc. SMP enough for helping me bring this change in my life."
                    text3={SMP_FOR_WOMEN_CARD_FOOTER}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforwomen/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Geroge Fernandes"
                    text2="I recently had an exceptional experience at Micro Inc. SMP! The expertise at Micro Inc. SMP shone through as they patiently addressed all my queries, instilling in me a sense of confidence about the procedure. Under the skilled hands of Chetan Mahajan, I achieved natural-looking results that have significantly bolstered my self-assurance. Moreover, the minimal post-procedure care required is a definite advantage. I wholeheartedly recommend Micro Inc. for those seeking a non-surgical hair loss solution – it truly transformed my life! Special thanks to Chetan Mahajan for his outstanding work! As someone who sought hair treatment, I can confidently say that it's worth every penny."
                    text3={SMP_FOR_MEN_CARD_FOOTER}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpformen/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Uday Karkera"
                    text2="Few years back, I was suggested to opt for a patch from a repute clinic as I lacked sufficient donor area for a hair transplant, a suggestion I didn't pursue. Recently, a close friend recommended I explore Micro Inc. SMP for SMP. There, I met Chetan Mahajan, the Picasso I would say, in his Mahim studio. The procedure itself was painless, although each session required a few hours at a stretch. Chetan's skills and crafty hands ensured my comfort throughout. This innovation is truly remarkable, offering individuals with sparse hair the opportunity to transform their appearance and regain their confidence. Now, I confidently affirm that 50 is the new 40 :)"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Ronak Motta"
                    text2="I recently underwent the scalp micropigmentation procedure, and I couldn't be more thrilled with the results. As someone who has struggled with hair loss for years and have had a bald scalp, SMP has been a game-changer for me. From the initial consultation to the final session, the entire experience at Micro Inc. SMP was exceptional. The results exceeded my expectations. The skilled practitioner (Chetan Mahajan) meticulously created a natural-looking hairline that seamlessly blends with my existing hair. It's amazing how the treatment has significantly improved my confidence and overall appearance. “Yay I have a Hairline now”"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
            </div>
        </div>
    );
}

function TestimonialsHomeMainTablet() {
    const navigate = useNavigate();
    return (
        <div className='testimonials-home-main-tablet'>
            <div className='testimonials-home-head'>
                <div className='testimonials-home-head-column'>
                    {/* <div className='testimonials-home-text'>
                        TESTIMONIALS
                    </div> */}
                    <div className='testimonials-home-text2'>
                        Reviews
                    </div>
                </div>
            </div>
            <div className='testimonial-home-cards1'>
                <TestimonialCard
                    text1="Nilesh More"
                    text2="It was a wonderful experience with Chetan sir in his studio of Micro Inc. SMP. His experience, Passion and dedicated work is remarkable. Good Hygiene was maintained, pleasant atmosphere. He works with all his energy and enthusiasm and make client comfortable throughout the sessions . The most important thing is client satisfaction, he works till that extent where client gets satisfied from his work, Really Worth for money."
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Vinod Bhagat"
                    text2="I don't usually write reviews, but I felt compelled to share my experience with Micro Inc SMP because of the exceptional quality of his work. The art of Scalp Micropigmentation (SMP) requires skill and finesse, and Chetan Mahajan from Micro Inc SMP is a Master. This procedure has been transformative. He gave me the confident I needed and a new look in life. He's such a perfectionist and took the time to do it right and make sure I'm happy with the result for anyone feeling stuck and looking for a change, I highly recommend Chetan’s Micro Inc SMP. Choosing Chetan Mahajan for your SMP treatment is a decision you won't regret. Forever grateful!"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Clara Thomas"
                    text2="I dealt with hair loss as a woman for the past 3 years. I wasn't confident about how I looked. I tried almost every possible treatment to improve the condition but I wasn't happy. I was nervous at the start of the process but Chetan walked me through, explaining what he will be doing and made me comfortable. It was a painless process. Now my confidence is back and I can style and wear my hair in any way I want too. I can't thank Micro Inc. SMP enough for helping me bring this change in my life."
                    text3={SMP_FOR_WOMEN_CARD_FOOTER}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforwomen/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Geroge Fernandes"
                    text2="I recently had an exceptional experience at Micro Inc. SMP! The expertise at Micro Inc. SMP shone through as they patiently addressed all my queries, instilling in me a sense of confidence about the procedure. Under the skilled hands of Chetan Mahajan, I achieved natural-looking results that have significantly bolstered my self-assurance. Moreover, the minimal post-procedure care required is a definite advantage. I wholeheartedly recommend Micro Inc. for those seeking a non-surgical hair loss solution – it truly transformed my life! Special thanks to Chetan Mahajan for his outstanding work! As someone who sought hair treatment, I can confidently say that it's worth every penny."
                    text3={SMP_FOR_MEN_CARD_FOOTER}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpformen/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Uday Karkera"
                    text2="Few years back, I was suggested to opt for a patch from a repute clinic as I lacked sufficient donor area for a hair transplant, a suggestion I didn't pursue. Recently, a close friend recommended I explore Micro Inc. SMP for SMP. There, I met Chetan Mahajan, the Picasso I would say, in his Mahim studio. The procedure itself was painless, although each session required a few hours at a stretch. Chetan's skills and crafty hands ensured my comfort throughout. This innovation is truly remarkable, offering individuals with sparse hair the opportunity to transform their appearance and regain their confidence. Now, I confidently affirm that 50 is the new 40 :)"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Ronak Motta"
                    text2="I recently underwent the scalp micropigmentation procedure, and I couldn't be more thrilled with the results. As someone who has struggled with hair loss for years and have had a bald scalp, SMP has been a game-changer for me. From the initial consultation to the final session, the entire experience at Micro Inc. SMP was exceptional. The results exceeded my expectations. The skilled practitioner (Chetan Mahajan) meticulously created a natural-looking hairline that seamlessly blends with my existing hair. It's amazing how the treatment has significantly improved my confidence and overall appearance. “Yay I have a Hairline now”"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
            </div>
            {/* <div className='testimonial-home-cards2'>
                <TestimonialCard
                    text1="Ronak Motta"
                    text2="I recently underwent the scalp micropigmentation procedure, and I couldn't be more thrilled with the results. As someone who has struggled with hair loss for years and have had a bald scalp, SMP has been a game-changer for me. From the initial consultation to the final session, the entire experience at Micro Inc. SMP was exceptional. The results exceeded my expectations. The skilled practitioner (Chetan Mahajan) meticulously created a natural-looking hairline that seamlessly blends with my existing hair. It's amazing how the treatment has significantly improved my confidence and overall appearance. “Yay I have a Hairline now”"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <TestimonialCard
                    text1="Nilesh More"
                    text2="It was a wonderful experience with Chetan sir in his studio of Micro Inc. SMP. His experience, Passion and dedicated work is remarkable. Good Hygiene was maintained, pleasant atmosphere. He works with all his energy and enthusiasm and make client comfortable throughout the sessions . The most important thing is client satisfaction, he works till that extent where client gets satisfied from his work, Really Worth for money."
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
            </div> */}
        </div>
    );
}

function TestimonialsHomeMainMobile() {
    const navigate = useNavigate();
    const styles = {
        buttonContainer: {
            // backgroundColor: 'red',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItms: 'center',
            fontSize: '30px',
            margin: '0px 5px 0px 0px'
        },
        icon: {
            cursor: 'pointer',
            color: '#c5c5c5'
        },
    };

    const scrollRef = useRef(null);

    const scrollLeft = () => {
        scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    };

    const scrollRight = () => {
        scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    };
    return (
        <div className='testimonials-home-main-mobile'>
            <div className='testimonials-home-head'>
                <div className='testimonials-home-head-column'>
                    {/* <div className='testimonials-home-text'>
                        TESTIMONIALS
                    </div> */}
                    <div className='testimonials-home-text2'>
                        Reviews
                    </div>
                </div>
                {/* <div style={styles.buttonContainer}>
                    <LuChevronLeftCircle onClick={scrollLeft} style={styles.icon} />
                    <div style={{ width: '15px' }}></div>
                    <LuChevronRightCircle onClick={scrollRight} style={styles.icon} />
                </div> */}
            </div>
            <div ref={scrollRef} className='testimonial-home-cards'>
                <TestimonialCard
                    text1="Nilesh More"
                    text2="It was a wonderful experience with Chetan sir in his studio of Micro Inc. SMP. His experience, Passion and dedicated work is remarkable. Good Hygiene was maintained, pleasant atmosphere. He works with all his energy and enthusiasm and make client comfortable throughout the sessions . The most important thing is client satisfaction, he works till that extent where client gets satisfied from his work, Really Worth for money."
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Vinod Bhagat"
                    text2="I don't usually write reviews, but I felt compelled to share my experience with Micro Inc SMP because of the exceptional quality of his work. The art of Scalp Micropigmentation (SMP) requires skill and finesse, and Chetan Mahajan from Micro Inc SMP is a Master. This procedure has been transformative. He gave me the confident I needed and a new look in life. He's such a perfectionist and took the time to do it right and make sure I'm happy with the result for anyone feeling stuck and looking for a change, I highly recommend Chetan’s Micro Inc SMP. Choosing Chetan Mahajan for your SMP treatment is a decision you won't regret. Forever grateful!"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Clara Thomas"
                    text2="I dealt with hair loss as a woman for the past 3 years. I wasn't confident about how I looked. I tried almost every possible treatment to improve the condition but I wasn't happy. I was nervous at the start of the process but Chetan walked me through, explaining what he will be doing and made me comfortable. It was a painless process. Now my confidence is back and I can style and wear my hair in any way I want too. I can't thank Micro Inc. SMP enough for helping me bring this change in my life."
                    text3={SMP_FOR_WOMEN_CARD_FOOTER}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforwomen/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="George Fernandes"
                    text2="I recently had an exceptional experience at Micro Inc. SMP! The expertise at Micro Inc. SMP shone through as they patiently addressed all my queries, instilling in me a sense of confidence about the procedure. Under the skilled hands of Chetan Mahajan, I achieved natural-looking results that have significantly bolstered my self-assurance. Moreover, the minimal post-procedure care required is a definite advantage. I wholeheartedly recommend Micro Inc. for those seeking a non-surgical hair loss solution – it truly transformed my life! Special thanks to Chetan Mahajan for his outstanding work! As someone who sought hair treatment, I can confidently say that it's worth every penny."
                    text3={SMP_FOR_MEN_CARD_FOOTER}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpformen/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Uday Karkera"
                    text2="Few years back, I was suggested to opt for a patch from a repute clinic as I lacked sufficient donor area for a hair transplant, a suggestion I didn't pursue. Recently, a close friend recommended I explore Micro Inc. SMP for SMP. There, I met Chetan Mahajan, the Picasso I would say, in his Mahim studio. The procedure itself was painless, although each session required a few hours at a stretch. Chetan's skills and crafty hands ensured my comfort throughout. This innovation is truly remarkable, offering individuals with sparse hair the opportunity to transform their appearance and regain their confidence. Now, I confidently affirm that 50 is the new 40 :)"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Ronak Motta"
                    text2="I recently underwent the scalp micropigmentation procedure, and I couldn't be more thrilled with the results. As someone who has struggled with hair loss for years and have had a bald scalp, SMP has been a game-changer for me. From the initial consultation to the final session, the entire experience at Micro Inc. SMP was exceptional. The results exceeded my expectations. The skilled practitioner (Chetan Mahajan) meticulously created a natural-looking hairline that seamlessly blends with my existing hair. It's amazing how the treatment has significantly improved my confidence and overall appearance. “Yay I have a Hairline now”"
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={SMP_FOR_ALOPECIA_URL_1}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
            </div>
        </div>
    );
}

function TestimonialCard({ text1, text2, text3, image1, image2, onClickAction }) {
    return (
        <div className='testimonial-home-card-component-container' >
            <div className='testimonial-home-card-component-in'>
                <div className='testimonial-home-card-component-content-area'>
                    <div className='testimonial-home-card-component-text0'>
                        {text1}
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <MdOutlineStar style={{ color: '#e9be47' }} />
                            <MdOutlineStar style={{ color: '#e9be47' }} />
                            <MdOutlineStar style={{ color: '#e9be47' }} />
                            <MdOutlineStar style={{ color: '#e9be47' }} />
                            <MdOutlineStar style={{ color: '#e9be47' }} />
                        </div>
                    </div>
                    <div className='testimonial-home-card-component-text1'>
                        "{text2}"
                    </div>

                </div>
            </div>
        </div>
    );
}

export default TestimonialsHome;