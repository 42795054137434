import React, { useEffect } from 'react';
import '../styles/SmpForScarsPage/SmpForScarsPage.css';
import ConsultationForm from '../components/HomePage/ConsultationForm';
import Faq from '../components/HomePage/Faq';
import Footer from '../components/HomePage/Footer';
import Navbar from '../components/HomePage/Navbar';
import Home from '../components/SmpForScarsPage/Home';
import TreatmentsForScars from '../components/SmpForScarsPage/TreatmentsForScars';
import CompareChart from '../components/HomePage/CompareChart';
import AboutAlopecia from '../components/SmpForAlopeciaPage/AboutAlopecia';
import AboutScars from '../components/SmpForScarsPage/AboutScars';
import { useSelector, useDispatch } from 'react-redux';
import { selectShowDialog, setShowDialog } from '../state/slices'
import { selectDialogTitle, setDialogTitle } from '../state/slices'
import { selectDialogDesc, setDialogDesc } from '../state/slices'
import { selectShowLoading } from '../state/slices'
import FloatingWhatsAppButton from '../components/FloatingWhatsAppButton';
import useWindowDimensions from '../hooks/useWindowDimensions';

function SmpForScarsPage() {
  const { width } = useWindowDimensions();
  const isDesktop = width >= 1025;
  const isTablet = width >= 768 && width <= 1024;
  const isMobile = width <= 767;

  function reValue({ desktop, tablet, mobile }) {
    if (isDesktop) {
      return desktop;
    }
    if (isTablet) {
      return tablet;
    }
    if (isMobile) {
      return mobile;
    }
  }
  const showDialog = useSelector(selectShowDialog);
  const showLoading = useSelector(selectShowLoading);
  const dialogTitle = useSelector(selectDialogTitle);
  const dialogDesc = useSelector(selectDialogDesc);
  const dispatch = useDispatch();
  return (
    <div style={{ backgroundColor: "#fafafa", scrollbarWidth: 'none' }}>
      {/* <Navbar></Navbar> */}
      <div className='sfw-space-between-navbar'></div>
      <Home></Home>
      <div style={reValue({
        desktop: {
          height: '100px',
        },
        tablet: {
          height: '100px',
        },
        mobile: {
          height: '0px',
        }
      })}></div>
      <TreatmentsForScars></TreatmentsForScars>
      <div style={{ height: '140px' }}></div>
      <ConsultationForm></ConsultationForm>
      {/* <About></About> */}
      {/* <AboutScars></AboutScars> */}
      {/* <TreatmentsForScars></TreatmentsForScars> */}
      {/* <WhyNumber1></WhyNumber1> */}
      {/* <CustomerCards></CustomerCards> */}
      <CompareChart></CompareChart>
      <Faq></Faq>
      <Footer></Footer>
      <FloatingWhatsAppButton></FloatingWhatsAppButton>
      <Navbar></Navbar>
      {showLoading && (<div className="dialog-overlay">
        <div className="loading">
          <div className="spinner"></div>
        </div>
      </div>)}
      {showDialog && (<div className="dialog-overlay">
        <div className="dialog">
          <p style={{ fontWeight: 'bold' }}>{dialogTitle}</p>
          <p>{dialogDesc}</p>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <button style={{ backgroundColor: '#023273', color: 'white' }} onClick={() => {
              dispatch(setShowDialog(false));
            }}>Ok</button>
          </div>
        </div>
      </div>)}
    </div>
  );
}

export default SmpForScarsPage;
