import React from 'react';
import store from './state/store'
import { Provider } from 'react-redux'
import { useSelector, useDispatch } from 'react-redux';
import {RouterProvider} from "react-router-dom";
import { selectShowImgDialog1, setShowImgDialog1 } from './state/slices'
import ImgDialog1 from './components/ImgDialog1';

function App({ router }) {
    const dispatch = useDispatch();
    // const showImgDialog1 = useSelector(selectShowImgDialog1);
    const showImgDialog1 = false;

    return (
        <>
            <Provider store={store}>
                <RouterProvider router={router} />
            </Provider>

            {showImgDialog1 && (<ImgDialog1/>)}
        </>
    );
}

export default App;