import React, { useState, useEffect } from 'react';
import '../styles/PrivacyPolicyPage/PrivacyPolicyPage.css';
import Footer from '../components/HomePage/Footer';
import Navbar from '../components/HomePage/Navbar';
import { ChevronUp, ChevronDown } from 'lucide-react';
import { useNavigate } from "react-router-dom";

import { FAQ_TILE_10_DESCRIPTION, FAQ_TILE_10_HEADING, FAQ_TILE_1_DESCRIPTION, FAQ_TILE_1_HEADING, FAQ_TILE_2_DESCRIPTION, FAQ_TILE_2_HEADING, FAQ_TILE_3_DESCRIPTION, FAQ_TILE_3_HEADING, FAQ_TILE_4_DESCRIPTION, FAQ_TILE_4_HEADING, FAQ_TILE_5_DESCRIPTION, FAQ_TILE_5_HEADING, FAQ_TILE_6_DESCRIPTION, FAQ_TILE_6_HEADING, FAQ_TILE_7_DESCRIPTION, FAQ_TILE_7_HEADING, FAQ_TILE_8_DESCRIPTION, FAQ_TILE_8_HEADING, FAQ_TILE_9_DESCRIPTION, FAQ_TILE_9_HEADING } from '../constants';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { db, collection, getDocs, query, orderBy } from '../firebase';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import FloatingWhatsAppButton from '../components/FloatingWhatsAppButton';

const BLOGS_PER_PAGE = 9;

function TermsAndConditions() {
    const [blogs, setBlogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);

    const { width } = useWindowDimensions();
    const isDesktop = width >= 1025;
    const isTablet = width >= 768 && width <= 1024;
    const isMobile = width <= 767;

    function reValue({ desktop, tablet, mobile }) {
        if (isDesktop) {
            return desktop;
        }
        if (isTablet) {
            return tablet;
        }
        if (isMobile) {
            return mobile;
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchBlogs = async () => {
            try {
                const q = query(collection(db, 'blogs'), orderBy('createdAt', 'desc'));
                const querySnapshot = await getDocs(q);
                const blogData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setBlogs(blogData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching blogs: ", error);
            }
        };

        fetchBlogs();
    }, []);
    return (
        <div style={{ backgroundColor: "#fafafa", scrollbarWidth: 'none' }}>
            <div className='space-between-navbar'></div>
            {/* <Blog blogs={blogs} currentPage={currentPage} setCurrentPage={setCurrentPage} loading={loading}></Blog> */}
            <div style={reValue({
                desktop: { display: 'flex', flexDirection: 'row', justifyContent: 'center' },
                tablet: { display: 'flex', flexDirection: 'row', justifyContent: 'center' },
                mobile: { display: 'flex', flexDirection: 'row', justifyContent: 'center' },
            })}>
                <div style={reValue({
                    desktop: { width: '60%' },
                    tablet: { width: '90%' },
                    mobile: { width: '90%' },
                })}>
                    <div style={reValue({
                        desktop: { display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '130px 0px 60px 0px' },
                        tablet: { display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '130px 0px 60px 0px' },
                        mobile: { display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '130px 0px 60px 0px' },
                    })}>
                        <div className='be-blog-big-text'>
                            Terms & Conditions
                        </div>
                    </div>
                    <div>
                        <p>
                            These terms and conditions (the "Terms") govern your access to and use of M/s MICRO INC. SMP's website ("Site").
                            By accessing or using the Site you are agreeing to these Terms and entering into a legally binding contract with
                            M/s MICRO INC. SMP ("MIS"). It is important that you read carefully and understand the Terms. <strong>Do not access or use
                                the Site if you are unwilling or unable to be bound by the Terms.</strong>
                        </p>
                        <p>
                            Any references to "you" and "your" refer to you as a user of the Site. References to "we", "us", and "our" refer to MIS.
                        </p>
                        <h3>1. CHANGES TO THE TERMS AND CONDITIONS</h3>
                        <p>
                            We may modify the Terms from time to time. When changes are made we will notify you by making the revised version
                            available on this webpage and will indicate at the top of this page the date that revisions were last made. All
                            material modifications will apply prospectively only. You should revisit these Terms on a regular basis as revised
                            versions will be binding on you. Any such modification will be effective upon our posting of new Terms. You
                            understand and agree that your continued access to or use of the Site after any posted modification to the Terms
                            indicates your acceptance of the modification.
                        </p>
                        <h3>2. USING THE SITE</h3>
                        <p>
                            <strong>A. Permission to Use the Site:</strong> We grant you permission to use the Site subject to the restrictions
                            in these Terms. We may terminate your permission to use the Site for any conduct that we consider to be inappropriate, or for your breach of these Terms including the Restrictions listed in paragraph 4. Your use of the Site is at your own risk including the risk that you might be exposed to.
                        </p>
                        <p>
                            <strong>B. Site Availability:</strong> The Site may be modified, updated, interrupted, suspended, or discontinued
                            at any time for any or no reason without notice or liability to MIS.
                        </p>
                        <p>
                            <strong>C. User Details:</strong> You may need to provide your details to use part of the Site or have access to the Services rendered by us. We reserve the right to delete your details at any time at our sole discretion for any reason whatsoever. Furthermore we reserve the right to reveal your identity (including whatever information we know about you) in the event of a complaint or legal action arising from any message posted by you or when necessary to protect safety and security. You will not impersonate any other individual or entity in connection with your use of the Site.
                        </p>
                        <h3>3. CONTENT</h3>
                        <p>
                            <strong>A. Responsibility for Your Content:</strong> You alone are responsible for the content of your messages and you agree to indemnify and hold harmless MIS and our agents with respect to any claim based upon the transmission of your message(s). We reserve the right to remove any messages for any or no reason whatsoever.
                        </p>
                        <p>
                            <strong>B. MIS's Right to Use Your Content:</strong> By posting messages uploading files inputting data or engaging in any other form of communication through this Site you are granting MIS a royalty-free perpetual non-exclusive unrestricted fully transferable worldwide license to: (1) use copy sublicense adapt transmit publicly perform or display any such communication; and (2) sublicense to third parties the unrestricted right to exercise any of the foregoing rights granted with respect to the communication. The foregoing rights shall include the right to exploit any proprietary rights in such communication including but not limited to rights under copyright trademark service mark or patent laws under any relevant jurisdiction.
                        </p>
                        <p>
                            <strong>C. Ownership & Intellectual Property Rights:</strong> All material on the Site including but not limited to text data graphics logos button icons images audio clips video clips links digital downloads data compilations and software is owned controlled by or licensed to MIS and is protected by copyright trademark and other intellectual property rights. Further you acknowledge and agree that MIS owns all intellectual property rights title and interest in and to such content. Material on the Site is made available solely for your personal non-commercial use and may not be copied reproduced republished modified uploaded posted transmitted or distributed in any way including by e-mail or other electronic means without the express prior written consent of MIS in each instance. You may download material intentionally made available for downloading from the Site for your personal non-commercial use only provided that you keep intact any and all copyright and other proprietary notices that may appear on such materials. MIS may take appropriate legal steps against any person to prevent any such unauthorized use of its content or any breach of MIS’s intellectual property rights.
                        </p>
                        <h3>4. RESTRICTIONS</h3>
                        <p>
                            All the information on this Site is published in good faith and on an ‘AS IS’ basis and for general information purpose only. We do not make any warranties about the completeness reliability and accuracy of this information. Any action you take upon the information you find on this Site is strictly at your own risk. We will not be liable for any losses and/or damages in connection with your use of the Site.
                        </p>
                        <p>
                            <strong>A. You also agree not to and will not assist encourage or enable others to:</strong>
                            <br />
                            i. Restrict or inhibit any other user from using and enjoying the Site (for examples by means of hacking or defacement);
                            <br />
                            ii. Utilize any robot spider site search/retrieval application or other automated device process or means to access retrieve scrape or index any portion of the Site;
                            <br />
                            iii. Remove or modify any copyright trademark or other proprietary rights notice that appears on any portion of the Site or on any materials printed or copied from the Site;
                            <br />
                            iv. Record process or mine information about other users;
                            <br />
                            v. Reformat or frame any portion of the Site;
                            <br />
                            vi. Take any action that imposes or may impose in our sole discretion an unreasonable or disproportionately large load on MIS's technology infrastructure or otherwise make excessive traffic demands of the Site;
                            <br />
                            vii. Attempt to gain unauthorized access to the Site user accounts computer systems or networks connected to the Site through hacking password mining or any other means;
                            <br />
                            viii. Use any device software or routine that interferes with the proper working of the Site or otherwise attempt to interfere with the proper working of the Site;
                            <br />
                            ix. Use the Site to violate the security of any computer network crack passwords or security encryption codes; disrupt or interfere with the security of or otherwise cause harm to the Site; or
                            <br />
                            x. Remove circumvent disable damage or otherwise interfere with any security-related features of the Site features that prevent or restrict the use or copying of Site content or features that enforce limitations on the use of the Site.
                        </p>
                        <h3>5. PRIVACY POLICY</h3>
                        <p>
                            Use of the Site is also governed by our <a href='/privacypolicy/'>Privacy Policy</a>, which is incorporated into these Terms.
                        </p>
                        <h3>6. THIRD PARTIES</h3>
                        <p>
                            The Site may include links to other websites or applications (each a "Third Party Site"). Unless otherwise noted MIS does not operate control or endorse any information products or services on any Third-Party Site. You agree that we are not responsible for the availability or contents of any Third-Party Sites and that your use of any Third-Party Site is at your own risk.
                        </p>
                        <h3>7. LIABILITY</h3>
                        <p>
                            We assess treatment suitability before any procedure. If treatment is not suitable we will inform you of the reasons. We are not liable for any economic loss or special indirect or consequential damage arising out of the provision of goods and services. You must provide all relevant medical details prior to each treatment. We are not liable for damage resulting from failure to disclose such details. Compliance with all care instructions and recommendations is required.
                        </p>
                        <h3>8. DEPOSITS AND PAYMENTS</h3>
                        <p>
                            A non-refundable deposit may be required to secure your appointment which will be deducted from the treatment cost. Cancellations or no-shows will result in the deposit being used as a cancellation charge. Accepted payment methods include Cash Debit/Credit Cards.
                        </p>
                        <h3>9. CANCELLATIONS</h3>
                        <p>
                            You shall confirm your attendance upon receipt of appointment notification. Failure to confirm may result in reallocation of the appointment to another client. You are required to notify us 48 (forty-eight) hours in advance if you cannot attend your appointment. Kindly arrive 15 minutes in advance prior to the scheduled appointment. We may need to cancel or postpone appointments at short notice for reasons beyond our control. We will make every effort to contact you in advance but no compensation will be offered for postponed appointments.
                        </p>
                        <h3>10. INDEMNIFICATION</h3>
                        <p>
                            You agree to indemnify defend and hold harmless MIS and its affiliated companies officers directors employees agents licensors and suppliers from and against all losses expenses damages and costs including but not limited to reasonable attorneys' fees resulting from (i) your access to or use of the Site (ii) your violation of the Terms (iii) your use of any products or services purchased or obtained by you in connection with the Site or (iv) the infringement by you or any third party using your account of any intellectual property or other right of any person or entity. MIS reserves the right at your expense to assume the exclusive defense and control of any matter subject to indemnification by you.
                        </p>
                        <h3>11. WARRANTY DISCLAIMER AND LIMITATIONS OF LIABILITY</h3>
                        <p>
                            <strong>PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS THE LIABILITY OF MIS AND ITS AFFILIATES AND LICENSORS. EACH OF THESE SUBSECTIONS BELOW ONLY APPLIES UP TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW. NOTHING HEREIN IS INTENDED TO LIMIT ANY RIGHTS YOU MAY HAVE WHICH MAY NOT BE LAWFULLY LIMITED.</strong>
                        </p>
                        <p>
                            <strong>A. THE SITE IS MADE AVAILABLE TO YOU ON AN "AS IS" "WITH ALL FAULTS" AND "AS AVAILABLE" BASIS. YOUR USE OF THE SITE IS AT YOUR OWN DISCRETION AND RISK. MIS MAKES NO CLAIMS OR PROMISES ABOUT THE QUALITY ACCURACY OR RELIABILITY OF THE SITE ITS SAFETY OR SECURITY OR THE SITE CONTENT. ACCORDINGLY MIS IS NOT LIABLE TO YOU FOR ANY LOSS OR DAMAGE THAT MIGHT ARISE FROM YOUR USE OF THE SITE.</strong>
                        </p>
                        <p>
                            <strong>B. MIS EXPRESSLY DISCLAIMS ALL WARRANTIES WHETHER EXPRESS OR IMPLIED INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF MERCHANTABILITY FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. YOU ASSUME TOTAL RESPONSIBILITY AND RISK FOR YOUR USE OF THE SITE.</strong>
                        </p>
                        <p>
                            <strong>C. MIS ITS AFFILIATES AND ITS SPONSORS ARE NEITHER RESPONSIBLE NOR LIABLE FOR ANY DIRECT INDIRECT INCIDENTAL CONSEQUENTIAL SPECIAL EXEMPLARY PUNITIVE OR OTHER DAMAGES ARISING OUT OF OR RELATING IN ANY WAY TO THE SITE OR ANY THIRD PARTY SITE. YOUR SOLE AND EXCLUSIVE RIGHT AND REMEDY IN CASE OF DISSATISFACTION WITH THE SITE RELATED SERVICES AND/OR ANY THIRD PARTY SITE SHALL BE YOUR TERMINATION AND DISCONTINUATION OF ACCESS TO OR USE OF THE SITE.</strong>
                        </p>
                        <p>
                            <strong>D. MIS DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED ON THE SITE WILL BE UNINTERRUPTED OR ERROR-FREE THAT DEFECTS WILL BE CORRECTED OR THAT THE SITE OR ANY THIRD PARTY SITE OR THE SERVERS THAT MAKE THEM AVAILABLE ARE OR WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</strong>
                        </p>
                        <p>
                            <strong>E. MIS DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE MATERIALS ON THE SITE OR ANY THIRD PARTY SITE.</strong>
                        </p>
                        <p>
                            <strong>F. MIS MAKES NO REPRESENTATIONS OR WARRANTIES REGARDING HOW OFTEN THE MATERIALS OR INFORMATION POSTED ON THE SITE WILL BE UPDATED (IF AT ALL) OR THE NUMBER OR IDENTIFICATION OF THE RECORDING ARTISTS OR OTHER INDIVIDUALS (IF ANY) THAT WILL BE FEATURED ON THE SITE.</strong>
                        </p>
                        <h3>12. GOVERNING LAW AND JURISDICTION</h3>
                        <p>
                            These Terms and any dispute or claim arising under it will be governed by and construed in accordance with the laws of India. You agree that any legal action or proceedings arising out of these Terms or in connection with these Terms may be brought exclusively in the competent courts/ tribunals having jurisdiction in Mumbai and irrevocably submit themselves to the jurisdiction of such courts/ tribunals.
                        </p>
                        <h3>13. FORCE MAJEURE</h3>
                        <p>
                            MIS shall be under no liability whatsoever in the event of non-availability of any portion of the Site occasioned by a Force Majeure Event. A “Force Majeure Event” shall mean any event that is beyond our reasonable control and shall include without limitation sabotage fire flood explosion acts of God civil commotion strikes or industrial action of any kind riots insurrection war acts of government computer hacking unauthorized access to computer data and storage device computer crashes breach of security and encryption etc.
                        </p>
                        <h3>14. GENERAL TERMS</h3>
                        <p>
                            <strong>A. If any of the provisions of these Terms are deemed invalid void or for any reason unenforceable that part of these Terms will be deemed severable and will not affect the validity and enforceability of any remaining provisions of these Terms.</strong>
                        </p>
                        <p>
                            <strong>B. The Terms contain the entire agreement between you and us regarding the use of the Site and supersede any prior agreement between you and us on such subject matter. The parties acknowledge that no reliance is placed on any representation made but not expressly contained in these Terms.</strong>
                        </p>
                        <p>
                            <strong>C. We may provide you with notices including those regarding changes to the Terms by email regular mail or communications through the Site.</strong>
                        </p>
                        <p>
                            <strong>D. Any failure by MIS to exercise or enforce any right or provision of the Terms does not constitute a waiver of such right or provision. The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder.</strong>
                        </p>
                        <p>
                            <strong>E. The Terms are not assignable transferable or sublicensable by you except with MIS's prior written consent but may be assigned or transferred by us without restriction. Any assignment attempted to be made in violation of the Terms shall be void.</strong>
                        </p>
                        <p>
                            <strong>F. The section titles in the Terms are for convenience only and have no legal or contractual effect.</strong>
                        </p>
                    </div>
                    <div style={{ height: '200px' }}></div>
                </div>
            </div>
            <Footer></Footer>
            <FloatingWhatsAppButton></FloatingWhatsAppButton>
            <Navbar></Navbar>
        </div>
    );
}


function Blog({ blogs, currentPage, setCurrentPage, loading }) {
    return (
        <div>
            <BlogMainContainer blogs={blogs} currentPage={currentPage} setCurrentPage={setCurrentPage} loading={loading}></BlogMainContainer>
        </div>
    );
}


function BlogMainContainer({ blogs, currentPage, setCurrentPage, loading }) {
    const { width, height } = useWindowDimensions();

    const blogWrap = {
        display: 'flex',
        flexWrap: 'wrap',
        // gap: '30px', /* Adjust the gap between items */
        gap: width < 911 ? '7vw' : '2.1vw',
        margin: '',
        // alignItems: 'space-between',
        justifyContent: 'center',
        // padding: '10px',
        // border: '1px solid #ccc'
    };

    if (width < 911) {
        blogWrap.gap = '3vw';
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };


    const startIndex = (currentPage - 1) * BLOGS_PER_PAGE;
    const selectedBlogs = blogs.slice(startIndex, startIndex + BLOGS_PER_PAGE);

    return (
        <div className='be-blog-main-container'>
            <div className='be-blog-row'>
                <BlogMainHeading></BlogMainHeading>
                <DottedLine></DottedLine>
                {/* <div className='be-blog-right-side'>
                    <BlogTiles></BlogTiles>
                </div> */}
                <div style={{
                    // backgroundColor: 'black',
                    height: '80px',
                    width: '30px',
                }}>

                </div>
                <div style={blogWrap}>
                    {/* <BlogCard></BlogCard>
                    <BlogCard></BlogCard>
                    <BlogCard></BlogCard>
                    <BlogCard></BlogCard>
                    <BlogCard></BlogCard>
                    <BlogCard></BlogCard> */}
                    {/* {selectedBlogs.length > 0 ? (
                        selectedBlogs.map(blog => (
                            <BlogCard blog={blog}></BlogCard>

                        ))
                    ) : (
                        <p>No blogs available</p>
                    )} */}
                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0px 0px 100px 0px' }}>
                            <CircularProgress />
                        </Box>
                    ) : selectedBlogs.length > 0 ? (
                        selectedBlogs.map((blog, index) => (
                            <BlogCard key={index} blog={blog} />
                        ))
                    ) : (
                        <p>No blogs available</p>
                    )}
                </div>
                {/* <div className="pagination">
                    <button onClick={handlePrevPage} disabled={currentPage === 1}>
                        Previous
                    </button>
                    <button onClick={handleNextPage} disabled={startIndex + BLOGS_PER_PAGE >= blogs.length}>
                        Next
                    </button>
                </div> */}
                {(!loading && selectedBlogs.length > 0) ? (<div className="pagination" style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
                    <button
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                        style={{
                            padding: '10px 20px',
                            margin: '0 10px',
                            backgroundColor: '#023273',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            opacity: currentPage === 1 ? 0.5 : 1,
                            pointerEvents: currentPage === 1 ? 'none' : 'auto'
                        }}
                    >
                        Previous
                    </button>
                    <button
                        onClick={handleNextPage}
                        disabled={startIndex + BLOGS_PER_PAGE >= blogs.length}
                        style={{
                            padding: '10px 20px',
                            margin: '0 10px',
                            backgroundColor: '#023273',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            opacity: startIndex + BLOGS_PER_PAGE >= blogs.length ? 0.5 : 1,
                            pointerEvents: startIndex + BLOGS_PER_PAGE >= blogs.length ? 'none' : 'auto'
                        }}
                    >
                        Next
                    </button>
                </div>) : (<div></div>)}
            </div>
        </div>
    );
}

function BlogCard({ blog }) {
    const navigate = useNavigate();
    const { width } = useWindowDimensions();
    console.log(`the width here is: ${width}`);

    // Define the styles for different window widths
    const cardStyleDesktop = {
        backgroundColor: 'white',
        display: 'inline-block',
        margin: '0px',
        borderRadius: '8px',
        boxShadow: '0 0 20px #c9c9c95c',
        width: '28.5vw',
        height: '25vw',
        cursor: 'pointer',
        overflow: 'hidden', // Ensure the image doesn't overflow
    };

    const cardStyleTablet = {
        backgroundColor: 'white',
        display: 'inline-block',
        margin: '0px',
        borderRadius: '8px',
        boxShadow: '0 0 20px #c9c9c95c',
        width: '38.8vw',
        height: '35vw',
        cursor: 'pointer',
        overflow: 'hidden',
    };

    const cardStyleMobile = {
        backgroundColor: 'white',
        display: 'inline-block',
        margin: '0px',
        borderRadius: '8px',
        boxShadow: '0 0 20px #c9c9c95c',
        width: '90vw',
        height: '75vw',
        cursor: 'pointer',
        overflow: 'hidden',
    };

    // =================================================================================================================

    const titleStyleDesktop = {
        padding: '10px 20px 10px 20px',
        fontSize: '1.2vw', // Font size for the title
        fontWeight: 'bold', // Bold title
        color: '#333', // Dark color for contrast
        textAlign: 'left', // Center align text
        margin: '0', // Ensure no extra margin
    };

    const titleStyleTablet = {
        padding: '10px 20px 10px 20px',
        fontSize: '1.6vw', // Font size for the title
        fontWeight: 'bold', // Bold title
        color: '#333', // Dark color for contrast
        textAlign: 'left', // Center align text
        margin: '0', // Ensure no extra margin
    };

    const titleStyleMobile = {
        padding: '10px 20px 10px 20px',
        fontSize: '4vw', // Font size for the title
        fontWeight: 'bold', // Bold title
        color: '#333', // Dark color for contrast
        textAlign: 'left', // Center align text
        margin: '0', // Ensure no extra margin
    };

    // =================================================================================================================

    const dateTextStyleDesktop = {
        margin: '10px 0',
        fontSize: '0.9vw', // Smaller font size for date
        color: '#777', // Lighter color for date
        textAlign: 'left', // Center align text
        padding: '10px 2px 0px 20px',
    }

    const dateTextStyleTablet = {
        margin: '10px 0',
        fontSize: '1.25vw', // Smaller font size for date
        color: '#777', // Lighter color for date
        textAlign: 'left', // Center align text
        padding: '10px 2px 0px 20px',
    }

    const dateTextStyleMobile = {
        margin: '10px 0',
        fontSize: '2.6vw', // Smaller font size for date
        color: '#777', // Lighter color for date
        textAlign: 'left', // Center align text
        padding: '10px 2px 0px 20px',
    }

    // =================================================================================================================

    // Determine which style to use
    let cardStyle = cardStyleDesktop;
    let titleStyle = titleStyleDesktop;
    let dateTextStyle = dateTextStyleDesktop;

    if (width < 1025) {
        cardStyle = cardStyleTablet;
        if (width < 911) {
            cardStyle.width = '38.4vw'; // Fix the width adjustment for tablets
        }
        titleStyle = titleStyleTablet
        dateTextStyle = dateTextStyleTablet;
    }
    if (width < 767) {
        cardStyle = cardStyleMobile;
        titleStyle = titleStyleMobile;
        dateTextStyle = dateTextStyleMobile;
    }

    return (
        <div
            style={cardStyle}
            onClick={() => {
                window.scrollTo(0, 0);
                navigate(`/blog/${blog.slug}`);
            }}
        >
            <img
                style={{
                    width: '100%', // Fit to the width of the card
                    height: '60%', // Adjust height as needed
                    objectFit: 'cover', // Ensure image covers the card area
                }}
                src={blog.imageUrl}
                alt={blog.title}
            />
            <p style={dateTextStyle}>
                {new Date(blog.createdAt.seconds * 1000).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).toUpperCase()}
            </p>
            <div style={titleStyle}>
                {blog.title}
            </div>
        </div>
    );
}



function BlogMainHeading() {
    return (
        <div className='be-blog-main-heading'>
            <div className='be-blog-big-text'>
                Blog
            </div>
            <div className='be-blog-small-text'>

            </div>
        </div>
    );
}

function DottedLine() {
    return (
        <div className='be-dotted-line-row'>
            <div className='be-dotted-line'></div>
        </div>
    );
}

function BlogTiles() {
    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [visible3, setVisible3] = useState(false);
    const [visible4, setVisible4] = useState(false);
    const [visible5, setVisible5] = useState(false);
    // ................................................
    const [visible6, setVisible6] = useState(false);
    const [visible7, setVisible7] = useState(false);
    const [visible8, setVisible8] = useState(false);
    const [visible9, setVisible9] = useState(false);
    const [visible10, setVisible10] = useState(false);

    return (
        <div className='be-expandable-tiles-column'>
            <ExpandableTile visible={visible1} onClick={(event) => {
                event.preventDefault();
                setVisible1(!visible1);
                if (visible1 === false) {
                    // setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
            }} title={FAQ_TILE_1_HEADING} content={FAQ_TILE_1_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible2(!visible2);
                if (visible2 === false) {
                    setVisible1(false);
                    // setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
            }} visible={visible2} title={FAQ_TILE_2_HEADING} content={FAQ_TILE_2_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible3(!visible3);
                if (visible3 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    // setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
                // <img className='home-video-component' src={SMP_FOR_SCARS_BANNER_IMAGE}></img>
            }} visible={visible3} title={FAQ_TILE_3_HEADING} content={FAQ_TILE_3_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible4(!visible4);
                if (visible4 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    // setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
            }} visible={visible4} title={FAQ_TILE_4_HEADING} content={FAQ_TILE_4_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible5(!visible5);
                if (visible5 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    // setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
            }} visible={visible5} title={FAQ_TILE_5_HEADING} content={FAQ_TILE_5_DESCRIPTION}></ExpandableTile>
            {/* ----------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
            <ExpandableTile visible={visible6} onClick={(event) => {
                event.preventDefault();
                setVisible6(!visible6);
                if (visible6 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    // setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
            }} title={FAQ_TILE_6_HEADING} content={FAQ_TILE_6_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible7(!visible7);
                if (visible7 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    // setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
            }} visible={visible7} title={FAQ_TILE_7_HEADING} content={FAQ_TILE_7_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible8(!visible8);
                if (visible8 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    // setVisible8(false);
                    setVisible9(false);
                    setVisible10(false);
                }
                // <img className='home-video-component' src={SMP_FOR_SCARS_BANNER_IMAGE}></img>
            }} visible={visible8} title={FAQ_TILE_8_HEADING} content={FAQ_TILE_8_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible9(!visible9);
                if (visible9 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    // setVisible9(false);
                    setVisible10(false);
                }
            }} visible={visible9} title={FAQ_TILE_9_HEADING} content={FAQ_TILE_9_DESCRIPTION}></ExpandableTile>
            <ExpandableTile onClick={(event) => {
                event.preventDefault();
                setVisible10(!visible10);
                if (visible10 === false) {
                    setVisible1(false);
                    setVisible2(false);
                    setVisible3(false);
                    setVisible4(false);
                    setVisible5(false);
                    setVisible6(false);
                    setVisible7(false);
                    setVisible8(false);
                    setVisible9(false);
                    // setVisible10(false);
                }
            }} visible={visible10} title={FAQ_TILE_10_HEADING} content={FAQ_TILE_10_DESCRIPTION}></ExpandableTile>

        </div>
    );
}

function ExpandableTile({ visible, title, content, onClick }) {
    return (
        <div className='be-expandable-tile'>
            <div className='be-expandable-tile-heading'>
                <div className='be-expandable-tile-heading-text' onClick={onClick}>
                    {title}
                </div>
                <div>
                    {visible ? <ChevronUp /> : <ChevronDown />}
                </div>
            </div>
            <div style={{
                display: `${visible ? 'block' : 'none'}`,
                overflow: 'hidden',
                margin: '20px 0px 0px 0px',
                width: '93%',
                lineHeight: '24px'
            }}>
                {visible && content}
            </div>
        </div>
    );
}

export default TermsAndConditions;