import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db, query, collection, where, getDocs } from '../firebase'; // Assuming db is correctly exported from your firebase config
import parse from 'html-react-parser';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import '../styles/BlogDetailsPage/BlogDetailsPage.css';
import Footer from '../components/HomePage/Footer';
import Navbar from '../components/HomePage/Navbar';
import FloatingWhatsAppButton from '../components/FloatingWhatsAppButton';
import useWindowDimensions from '../hooks/useWindowDimensions';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function BlogDetailsPage() {
  const { width } = useWindowDimensions();
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const blogQuery = query(collection(db, 'blogs'), where('slug', '==', slug));
        const querySnapshot = await getDocs(blogQuery);
        if (!querySnapshot.empty) {
          const blogData = querySnapshot.docs[0].data();
          if (blogData.content) {
            blogData.content = deltaToHtml(JSON.parse(blogData.content));
          }
          setBlog(blogData);
        } else {
          setNoData(true);
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching blog:', error);
      }
      setLoading(false);
    };

    fetchBlog();
  }, [slug]);

  // if (!blog) {
  //   return <div>Loading...</div>;
  // }

  const dateTextStyleDesktop = {
    margin: '10px 0',
    // fontSize: '0.9vw', // Smaller font size for date
    fontSize: '0.9em',
    color: '#777', // Lighter color for date
    textAlign: 'left', // Center align text
    padding: '0px 2px 10px 0px',
  }

  const dateTextStyleTablet = {
    margin: '10px 0',
    // fontSize: '1.25vw', // Smaller font size for date
    fontSize: '0.9em',
    color: '#777', // Lighter color for date
    textAlign: 'left', // Center align text
    padding: '0px 2px 10px 0px',
  }

  const dateTextStyleMobile = {
    margin: '10px 0',
    // fontSize: '2.6vw', // Smaller font size for date
    fontSize: '0.9em',
    color: '#777', // Lighter color for date
    textAlign: 'left', // Center align text
    padding: '0px 2px 10px 0px',
  }

  let dateTextStyle = dateTextStyleDesktop;

  if (width < 1025) {
    dateTextStyle = dateTextStyleTablet;
  }
  if (width < 767) {
    dateTextStyle = dateTextStyleMobile;
  }

  return (
    <div className='blog-details-pg'>
      <div className='space-between-navbar-bl'></div>
      {
        loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </Box>
        ) : noData ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div>No data found</div>
          </Box>
        ) : (<div className="blog-details">
          <h1 className="blog-title">{blog.title}</h1>
          <p style={dateTextStyle}>
            {new Date(blog.createdAt.seconds * 1000).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).toUpperCase()}
          </p>
          <img src={blog.imageUrl} alt={blog.title} className="blog-image" />
          <div className="blog-content">{parse(blog.content)}</div>
        </div>)
      }
      <div style={{ height: '100px' }}></div>
      <Footer />
      <FloatingWhatsAppButton></FloatingWhatsAppButton>
      <Navbar />
    </div>
  );
}

function deltaToHtml(delta) {
  const converter = new QuillDeltaToHtmlConverter(delta, {});
  return converter.convert();
}

export default BlogDetailsPage;
