import { configureStore } from '@reduxjs/toolkit';
import { countReducer, nameReducer, genderReducer, serviceReducer, showDialogReducer, showImgDialog1Reducer, showLoadingReducer, firstNameReducer, lastNameReducer, phoneReducer, emailReducer, messageReducer, cityReducer, dialogTitleReducer, dialogDescReducer } from './slices';

export default configureStore({
  reducer: {
    count: countReducer,
    name: nameReducer,
    gender: genderReducer,
    service: serviceReducer,
    showDialog: showDialogReducer,
    showImgDialog1: showImgDialog1Reducer,
    showLoading: showLoadingReducer,
    firstName: firstNameReducer,
    lastName: lastNameReducer,
    phone: phoneReducer,
    email: emailReducer,
    message: messageReducer,
    city: cityReducer,
    dialogTitle: dialogTitleReducer,
    dialogDesc: dialogDescReducer,
  },
});
