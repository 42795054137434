// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, doc, getDoc, query, orderBy, where } from 'firebase/firestore';

// const firebaseConfig = {
//     apiKey: "AIzaSyCNRKF3gwiyep0C_ehWK5T12WZDi5UiIfU",
//     authDomain: "smpdashboard-ffbfa.firebaseapp.com",
//     projectId: "smpdashboard-ffbfa",
//     storageBucket: "smpdashboard-ffbfa.appspot.com",
//     messagingSenderId: "622766633638",
//     appId: "1:622766633638:web:6fb859e14caed5f7807a67",
//     measurementId: "G-3HFMZ21RQ2"
// };

const firebaseConfig = {
    apiKey: "AIzaSyDGf6I6vgyIpAzRmmf6kLSp23yFrH-pUI4",
    authDomain: "micro-inc-smp.firebaseapp.com",
    projectId: "micro-inc-smp",
    storageBucket: "micro-inc-smp.appspot.com",
    messagingSenderId: "1020499138843",
    appId: "1:1020499138843:web:d28d13a97d0907d7e99bbe",
    measurementId: "G-NEMZH93FQD"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db, collection, getDocs, doc, getDoc, query, orderBy, where };