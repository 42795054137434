import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const FloatingWhatsAppButton = () => {
  const whatsappUrl = "https://wa.me/+919820011996"; // Replace 'your-phone-number' with your WhatsApp number

  const buttonStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    width: '50px',
    height: '50px',
    backgroundColor: '#25D366', // WhatsApp green color
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    zIndex: 1000,
    color: 'white',
    fontSize: '24px'
  };

  return (
    <a href={whatsappUrl} target="_blank" rel="noopener noreferrer" style={buttonStyle}>
      <FaWhatsapp style={{height: '30px', width: '30px'}} />
    </a>
  );
};

export default FloatingWhatsAppButton;
