import React, { useState, useEffect } from 'react';
import '../../styles/HomePage/Navbar.css';
import { useNavigate, useLocation } from "react-router-dom";
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { ChevronUp, ChevronDown } from 'lucide-react';
import FadeText from '../FadeText';

function Navbar() {
    const location = useLocation();

    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [submenuOpen, setSubmenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
        setSubmenuOpen(false)
    };

    const toggleSubmenu = () => {
        setSubmenuOpen(!submenuOpen);
    };

    const { width } = useWindowDimensions();

    return (
        <header className='navbar-container'>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
                {/* <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#023273', height: '45px', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                    <p>"Live Bold, Not Bald"</p>
                </div> */}
                <FadeText />
                <div className='navbar-container-in'>
                    <div className='navbar-left-side' onClick={() => {
                        // console.log(`location.pathname = ${location.pathname}`);
                        if (location.pathname == '/') {
                            window.scrollTo(0, 0);
                            return;
                        }
                        navigate("/");
                        window.scrollTo(0, 0);
                    }}>
                        <div className="image-container">
                            <img src="/logo-no-text.png" alt="Your Image" className="circular-image" />
                        </div>
                        <div className='micro-inc-text'>
                            Micro Inc. SMP
                        </div>
                    </div>
                    <div className='navbar-right-side'>
                        {/* <div className='nav-option'>
                        Treatments
                    </div>
                    <div className='nav-option'>
                        Trainings
                    </div> */}
                        {/* <button className="transparent-button2" onClick={() => {
                        navigate("/test/");
                    }}>
                        Book a free consultation
                    </button> */}
                        <button className="hamburger-menu" onClick={toggleMenu}>
                            <div className={`line ${isOpen ? 'open' : ''}`}></div>
                            <div className={`line ${isOpen ? 'open' : ''}`}></div>
                            <div className={`line ${isOpen ? 'open' : ''}`}></div>
                        </button>
                        <ul className={isOpen ? 'nav-menu active' : 'nav-menu'}>
                            <SpaceBetween></SpaceBetween>
                            <li><div className={location.pathname === '/' ? 'nav-menu-title-underlined' : 'nav-menu-title'} onClick={() => {
                                if (location.pathname === '/') {
                                    window.scrollTo(0, 0);
                                    return;
                                }
                                window.scrollTo(0, 0);
                                navigate('/');
                            }}>H O M E</div></li>
                            <SpaceBetween></SpaceBetween>
                            <li className='options-menu-container'>
                                <div className={['/smpformen/', '/smpforwomen/', '/smpforalopecia/', '/smpforscars/'].includes(location.pathname) ? 'nav-menu-title-underlined' : 'nav-menu-title'} onClick={toggleSubmenu}>T R E A T M E N T S</div>
                                <ul className={`submenu ${submenuOpen ? 'active' : ''}`}>
                                    <div style={{ height: "12px" }}></div>
                                    <SpaceBetween2></SpaceBetween2>
                                    <li><div className={location.pathname === '/smpformen/' ? 'nav-menu-title-underlined' : 'nav-menu-title'} onClick={() => {
                                        if (location.pathname === '/smpformen/') {
                                            window.scrollTo(0, 0);
                                            return;
                                        }
                                        window.scrollTo(0, 0);
                                        navigate('/smpformen/');
                                    }}>SMP for Men</div></li>
                                    <SpaceBetween2></SpaceBetween2>
                                    <li><div className={location.pathname === '/smpforwomen/' ? 'nav-menu-title-underlined' : 'nav-menu-title'} onClick={() => {
                                        if (location.pathname === '/smpforwomen/') {
                                            window.scrollTo(0, 0);
                                            return;
                                        }
                                        window.scrollTo(0, 0);
                                        navigate('/smpforwomen/');
                                    }}>SMP for Women</div></li>
                                    <SpaceBetween2></SpaceBetween2>
                                    <li><div className={location.pathname === '/smpforalopecia/' ? 'nav-menu-title-underlined' : 'nav-menu-title'} onClick={() => {
                                        if (location.pathname === '/smpforalopecia/') {
                                            window.scrollTo(0, 0);
                                            return;
                                        }
                                        window.scrollTo(0, 0);
                                        navigate('/smpforalopecia/');
                                    }}>SMP for Alopecia</div></li>
                                    <SpaceBetween2></SpaceBetween2>
                                    <li><div className={location.pathname === '/smpforscars/' ? 'nav-menu-title-underlined' : 'nav-menu-title'} onClick={() => {
                                        if (location.pathname === '/smpforscars/') {
                                            window.scrollTo(0, 0);
                                            return;
                                        }
                                        window.scrollTo(0, 0);
                                        navigate('/smpforscars/');
                                    }}>SMP for Scars</div></li>
                                </ul>
                            </li>
                            <SpaceBetween></SpaceBetween>
                            <li><div className={location.pathname === '/faq/' ? 'nav-menu-title-underlined' : 'nav-menu-title'} onClick={() => {
                                if (location.pathname === '/faq/') {
                                    window.scrollTo(0, 0);
                                    return;
                                }
                                window.scrollTo(0, 0);
                                navigate('/faq/');
                            }}>F A Q</div></li>
                            <SpaceBetween></SpaceBetween>
                            <li><div className={location.pathname === '/blog/' ? 'nav-menu-title-underlined' : 'nav-menu-title'} onClick={() => {
                                if (location.pathname === '/blog/') {
                                    window.scrollTo(0, 0);
                                    return;
                                }
                                window.scrollTo(0, 0);
                                navigate('/blog/');
                            }}>B L O G</div></li>
                            <SpaceBetween></SpaceBetween>
                            <li><div className='nav-menu-title' onClick={() => {
                                // window.scrollTo({
                                //     top: document.documentElement.scrollHeight,
                                //     behavior: 'smooth'
                                // });
                                if (location.pathname === '/') {
                                    setIsOpen(false);
                                    if (window.innerWidth <= 767) {
                                        // mobile
                                        window.scrollTo(0, 6600);
                                    } else {
                                        // tablet
                                        window.scrollTo(0, 5040);
                                    }
                                    return;
                                }

                                if (location.pathname === '/smpformen/') {
                                    setIsOpen(false);
                                    if (window.innerWidth <= 767) {
                                        // mobile
                                        window.scrollTo(0, 3750);
                                    } else {
                                        // tablet
                                        window.scrollTo(0, 3350);
                                    }
                                    return;
                                }

                                if (location.pathname === '/smpforwomen/') {
                                    setIsOpen(false);
                                    if (window.innerWidth <= 767) {
                                        // mobile
                                        window.scrollTo(0, 3300);
                                    } else {
                                        // tablet
                                        window.scrollTo(0, 2700);
                                    }
                                    return;
                                }

                                if (location.pathname === '/smpforalopecia/') {
                                    setIsOpen(false);
                                    if (window.innerWidth <= 767) {
                                        // mobile
                                        window.scrollTo(0, 2550);
                                    } else {
                                        // tablet
                                        window.scrollTo(0, 2300);
                                    }
                                    return;
                                }

                                if (location.pathname === '/smpforscars/') {
                                    setIsOpen(false);
                                    if (window.innerWidth <= 767) {
                                        // mobile
                                        window.scrollTo(0, 2150);
                                    } else {
                                        // tablet
                                        window.scrollTo(0, 2000);
                                    }
                                    return;
                                }

                                if (location.pathname === '/blog/') {
                                    setIsOpen(false);
                                    window.scrollTo({
                                        top: document.documentElement.scrollHeight,
                                        behavior: 'smooth'
                                    });
                                    return;
                                }

                                if (location.pathname === '/faq/') {
                                    setIsOpen(false);
                                    window.scrollTo({
                                        top: document.documentElement.scrollHeight,
                                        behavior: 'smooth'
                                    });
                                    return;
                                }

                                setIsOpen(false);
                                window.scrollTo({
                                    top: document.documentElement.scrollHeight,
                                    behavior: 'smooth'
                                });
                                return;

                            }}>C O N T A C T</div></li>
                            <SpaceBetween></SpaceBetween>
                        </ul>
                        <ul className='nav-menu2'>
                            <li><div className={location.pathname === '/' ? 'nav-menu-title-underlined' : 'nav-menu-title'} onClick={() => {
                                if (location.pathname === '/') {
                                    window.scrollTo(0, 0);
                                    return;
                                }
                                window.scrollTo(0, 0);
                                navigate('/');
                            }}>Home</div></li>
                            <li className="options-menu-container">
                                <div className={['/smpformen/', '/smpforwomen/', '/smpforalopecia/', '/smpforscars/'].includes(location.pathname) ? 'nav-menu-title-underlined' : 'nav-menu-title'}>Treatments</div>
                                <ul className="submenu">
                                    <li className='submenu-li' onClick={() => {
                                        if (location.pathname === '/smpformen/') {
                                            window.scrollTo(0, 0);
                                            return;
                                        }
                                        window.scrollTo(0, 0);
                                        navigate('/smpformen/');
                                    }}><div>SMP for Men</div></li>
                                    {/* <SpaceBetween3></SpaceBetween3> */}
                                    <li className='submenu-li' onClick={() => {
                                        if (location.pathname === '/smpforwomen/') {
                                            window.scrollTo(0, 0);
                                            return;
                                        }
                                        window.scrollTo(0, 0);
                                        navigate('/smpforwomen/');
                                    }}><div>SMP for Women</div></li>
                                    {/* <SpaceBetween3></SpaceBetween3> */}
                                    <li className='submenu-li' onClick={() => {
                                        if (location.pathname === '/smpforalopecia/') {
                                            window.scrollTo(0, 0);
                                            return;
                                        }
                                        window.scrollTo(0, 0);
                                        navigate('/smpforalopecia/');
                                    }}><div>SMP for Alopecia</div></li>
                                    {/* <SpaceBetween3></SpaceBetween3> */}
                                    <li className='submenu-li' onClick={() => {
                                        if (location.pathname === '/smpforscars/') {
                                            window.scrollTo(0, 0);
                                            return;
                                        }
                                        window.scrollTo(0, 0);
                                        navigate('/smpforscars/');
                                    }}><div>SMP for Scars</div></li>
                                </ul>
                            </li>
                            <li><div className={location.pathname === '/faq/' ? 'nav-menu-title-underlined' : 'nav-menu-title'} onClick={() => {
                                if (location.pathname === '/faq/') {
                                    window.scrollTo(0, 0);
                                    return;
                                }
                                window.scrollTo(0, 0);
                                navigate('/faq/');
                            }}>FAQ</div></li>
                            <li><div className={location.pathname === '/blog/' ? 'nav-menu-title-underlined' : 'nav-menu-title'} onClick={() => {
                                if (location.pathname === '/blog/') {
                                    window.scrollTo(0, 0);
                                    return;
                                }
                                window.scrollTo(0, 0);
                                navigate('/blog/');
                            }}>Blog</div></li>
                            <li><div className='nav-menu-title' onClick={() => {
                                // window.scrollTo({
                                //     top: document.documentElement.scrollHeight,
                                //     behavior: 'smooth'
                                // });
                                if (location.pathname === '/') {
                                    if (width >= 1025) {
                                        window.scrollTo(0, 3900);
                                    } else {
                                        window.scrollTo(0, 4900);
                                    }
                                    return;
                                }

                                if (location.pathname === '/smpformen/') {
                                    if (width >= 1025) {
                                        window.scrollTo(0, 2600);
                                    } else {
                                        window.scrollTo(0, 4900);
                                    }
                                    return;
                                }

                                if (location.pathname === '/smpforwomen/') {
                                    if (width >= 1025) {
                                        window.scrollTo(0, 2770);
                                    } else {
                                        window.scrollTo(0, 4900);
                                    }
                                    return;
                                }

                                if (location.pathname === '/smpforalopecia/') {
                                    if (width >= 1025) {
                                        window.scrollTo(0, 2100);
                                    } else {
                                        window.scrollTo(0, 4900);
                                    }
                                    return;
                                }

                                if (location.pathname === '/smpforscars/') {
                                    if (width >= 1025) {
                                        window.scrollTo(0, 1970);
                                    } else {
                                        window.scrollTo(0, 4900);
                                    }
                                    return;
                                }

                                if (location.pathname === '/blog/') {
                                    window.scrollTo({
                                        top: document.documentElement.scrollHeight,
                                        behavior: 'smooth'
                                    });
                                    return;
                                }

                                if (location.pathname.includes('blog')) {
                                    window.scrollTo({
                                        top: document.documentElement.scrollHeight,
                                        behavior: 'smooth'
                                    });
                                    return;
                                }

                                if (location.pathname === '/faq/') {
                                    window.scrollTo({
                                        top: document.documentElement.scrollHeight,
                                        behavior: 'smooth'
                                    });
                                    return;
                                }

                                window.scrollTo({
                                    top: document.documentElement.scrollHeight,
                                    behavior: 'smooth'
                                });
                                return;

                            }}>Contact</div></li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    );
}

function SpaceBetween() {
    return (
        <div style={{ height: "50px" }}>
        </div>
    );
}

function SpaceBetween2() {
    return (
        <div style={{ height: "30px" }}>
        </div>
    );
}

function SpaceBetween3() {
    return (
        <div style={{ height: "25px" }}>
        </div>
    );
}

export default Navbar;
