import React, { useState, useEffect } from 'react';
import '../styles/BlogPage/BlogPage.css';
import Footer from '../components/HomePage/Footer';
import Navbar from '../components/HomePage/Navbar';
import FloatingWhatsAppButton from '../components/FloatingWhatsAppButton';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { FaFacebook, FaInstagram, FaYoutube, FaTiktok, FaWhatsapp } from 'react-icons/fa';
import { CONTACT_LINK_FACEBOOK, CONTACT_LINK_INSTAGRAM, CONTACT_LINK_WHATSAPP } from '../constants';
import Testimonials from '../components/Testimonials';
import { margin } from '@mui/system';

function ThankyouPage() {
    useEffect(() => {
        window.gtag('event', 'conversion', {
            'send_to': 'AW-16672520200/d6sPCIyN0MoZEIjwiY4-'
        });
    }, []);

    const { width } = useWindowDimensions();
    const isDesktop = width >= 1025;
    const isTablet = width >= 768 && width <= 1024;
    const isMobile = width <= 767;

    function reValue({ desktop, tablet, mobile }) {
        if (isDesktop) {
            return desktop;
        }
        if (isTablet) {
            return tablet;
        }
        if (isMobile) {
            return mobile;
        }
    }

    return (
        <div style={{ backgroundColor: "#fafafa", scrollbarWidth: 'none' }}>
            <div style={reValue({
                desktop: { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '200px 0px 120px 0px', },
                tablet: { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '200px 0px 80px 0px' },
                mobile: { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '200px 0px 80px 0px' },
            })}>
                <RiVerifiedBadgeFill style={reValue({
                    desktop: { fontSize: '70px', color: 'green' },
                    tablet: { fontSize: '70px', color: 'green' },
                    mobile: { fontSize: '50px', color: 'green' },
                })} />
                <div style={reValue({
                    desktop: { display: 'flex', flexDirection: 'column', fontSize: '23px', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', color: 'grey', padding: '10px 0px 0px 0px' },
                    tablet: { display: 'flex', flexDirection: 'column', fontSize: '23px', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', color: 'grey', padding: '10px 0px 0px 0px' },
                    mobile: { display: 'flex', flexDirection: 'column', fontSize: '16px', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', color: 'grey', padding: '10px 0px 0px 0px' },
                })}>
                    Form submitted
                </div>
                <div style={reValue({
                    desktop: { display: 'flex', flexDirection: 'column', fontSize: '70px', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' },
                    tablet: { display: 'flex', flexDirection: 'column', fontSize: '70px', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' },
                    mobile: { display: 'flex', flexDirection: 'column', fontSize: '40px', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' },
                })}>
                    Thank you!
                </div>
                <div style={reValue({
                    desktop: { height: '20px' },
                    tablet: { height: '20px' },
                    mobile: { height: '15px' },
                })}></div>
                <div style={reValue({
                    desktop: { display: 'flex', flexDirection: 'column', fontSize: '18px', justifyContent: 'center', alignItems: 'center', color: 'grey' },
                    tablet: { display: 'flex', flexDirection: 'column', fontSize: '18px', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', color: 'grey' },
                    mobile: { display: 'flex', flexDirection: 'column', fontSize: '15px', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', color: 'grey' },
                })}>
                    We look forward to connecting with you soon.
                </div>
                <div style={{ display: 'flex', alignItems: 'center', margin: '40px 0px 0px 0px' }}>
                    <FaFacebook style={reValue({
                        desktop: { color: 'grey', cursor: 'pointer' },
                        tablet: { color: 'grey', cursor: 'pointer' },
                        mobile: { color: 'grey', cursor: 'pointer' },
                    })} size="25px" onClick={() => {
                        console.log('Facebook');
                        window.open(CONTACT_LINK_FACEBOOK, '_blank', 'noopener,noreferrer');
                    }} />
                    <div style={reValue({
                        desktop: { width: '15px' },
                        tablet: { width: '15px' },
                        mobile: { width: '15px' },
                    })}></div>
                    <FaInstagram style={reValue({
                        desktop: { color: 'grey', cursor: 'pointer' },
                        tablet: { color: 'grey', cursor: 'pointer' },
                        mobile: { color: 'grey', cursor: 'pointer' },
                    })} size="25px" onClick={() => {
                        console.log('Instagram');
                        window.open(CONTACT_LINK_INSTAGRAM, '_blank', 'noopener,noreferrer');
                    }} />
                    <div style={reValue({
                        desktop: { width: '15px' },
                        tablet: { width: '15px' },
                        mobile: { width: '15px' },
                    })}></div>
                    <FaWhatsapp style={reValue({
                        desktop: { color: 'grey', cursor: 'pointer' },
                        tablet: { color: 'grey', cursor: 'pointer' },
                        mobile: { color: 'grey', cursor: 'pointer' },
                    })} size="25px" onClick={() => {
                        console.log('WhatsApp');
                        window.open(CONTACT_LINK_WHATSAPP, '_blank', 'noopener,noreferrer');
                    }} />
                </div>
            </div>
            <div style={{ height: '0px' }}></div>
            <Testimonials></Testimonials>
            <Footer></Footer>
            <FloatingWhatsAppButton></FloatingWhatsAppButton>
            <Navbar></Navbar>
        </div>
    );
}

export default ThankyouPage;